import { IconButtonProps } from "@mui/material";
import { FC } from "react";

import { PlusIcon } from "components/icons";

import { FilledIconButton } from "./FilledIconButton";

export const PlusIconButton: FC<IconButtonProps> = (props) => {
  return (
    <FilledIconButton disableRipple {...props}>
      <PlusIcon sx={{ width: 30, height: 30 }} />
    </FilledIconButton>
  );
};
