import { FieldValues, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "./ControlledTextField";

export type FioFieldProps<T extends FieldValues> = ControlledTextFieldProps<T> & {
  trigger: UseFormTrigger<T>;
};

const fioRegexp = /^[a-zA-Zа-яА-Я\s-]{1,150}$/;

export const FioField = <T extends FieldValues>({
  name,
  rules,
  trigger,
  inputProps,
  ...props
}: FioFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("employees.labels.fio");

  return (
    <ControlledTextField
      name={name}
      rules={{
        pattern: {
          value: fioRegexp,
          message: t("employees.errors.invalid_fio")
        },
        maxLength: {
          value: 150,
          message: t("common.errors.max_length", { count: 150 })
        },
        onChange: () => trigger(name),
        ...rules
      }}
      inputProps={{ "aria-label": ariaLabel, maxLength: 150, ...inputProps }}
      placeholder={ariaLabel}
      isValid={({ field, fieldState }) => !fieldState.invalid && fioRegexp.test(field.value)}
      {...props}
    />
  );
};
