import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const COMPANY_VEHICLES_QUERY_KEY = ["company_vehicles"];

export const getCompanyVehiclesQueryKey = (
  page: number,
  rowsPerPage: number,
  searchString: string
) => {
  const queryKey: (number | string)[] = [...COMPANY_VEHICLES_QUERY_KEY, page, rowsPerPage];

  if (searchString) {
    queryKey.push(searchString);
  }

  return queryKey;
};

export const useCompanyVehiclesQuery = (page: number, rowsPerPage: number, searchString: string) =>
  useQuery({
    queryKey: getCompanyVehiclesQueryKey(page, rowsPerPage, searchString),
    queryFn: () =>
      requests.legalCabinetGatewayVehiclesByCompanyUuid({
        page,
        limit: rowsPerPage,
        q: searchString
      })
  });
