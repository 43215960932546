import { FC } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledCheckbox } from "components/form";

import { SignUpFormInputs } from "./types";

interface AgreementCheckboxFieldProps {
  control: Control<SignUpFormInputs>;
}

export const AgreementCheckboxField: FC<AgreementCheckboxFieldProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <ControlledCheckbox
      fullWidth
      sx={{ mt: "54px" }}
      label={t("auth.labels.agreement")}
      name='agreement'
      control={control}
      rules={{
        validate: (value) => value || t("auth.errors.agreement_required")
      }}
    />
  );
};
