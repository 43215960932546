import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const CancelButton = styled(Button)({
  color: "#637381",
  boxShadow: "0 0 0 1px inset #E9EDF3",
  "&: hover": {
    color: "#637381",
    boxShadow: "0 0 0 2px inset #CCCCCC"
  },
  "&: active": {
    color: "#FFFFFF",
    boxShadow: "none",
    backgroundColor: "#637381"
  }
});
