import { IconButton, IconButtonProps, styled } from "@mui/material";
import { FC } from "react";

export const FilledIconButton: FC<IconButtonProps> = styled(IconButton)({
  width: 30,
  height: 30,
  backgroundColor: "#ACB6BE",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#B8C3CB"
  },
  "&:active": {
    backgroundColor: "#17BD31"
  }
});
