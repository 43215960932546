import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const SelectSharpIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15.0364 11L12.0182 14L9 11' stroke='currentColor' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
};
