import { Alert, Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { LegalCabinetGatewayV1Vehicle } from "api/generated";
import { Pagination } from "components/pagination";

import { useCompanyVehiclesQuery } from "../hooks";

import { AddVehicleForm } from "./AddVehicleForm";
import { ChangeVehicleForm } from "./ChangeVehicleForm";
import { DeleteVehicleDialog } from "./DeleteVehicleDialog";
import { VehiclesSearchField } from "./VehiclesSearchField";
import { VehiclesTable } from "./VehiclesTable";

export const VehiclesManagement: FC = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [searchString, setSearchString] = useState<string>("");

  const { data, isPending, error } = useCompanyVehiclesQuery(
    page - 1,
    rowsPerPage,
    searchString.length >= 3 ? searchString : ""
  );

  const vehicles = data?.data.vehicles;
  const totalVehicles = data?.data.total ?? 0;
  const vehiclesAreEmpty = !vehicles || vehicles.length === 0;
  const errorToRender = error || data?.data.error;

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const [changeDialogOpen, setChangeDialogOpen] = useState<boolean>(false);
  const [vehicleToChange, setVehicleToChange] = useState<LegalCabinetGatewayV1Vehicle | null>(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<LegalCabinetGatewayV1Vehicle | null>(null);

  const handleSearchStringChange = useDebouncedCallback((newSearchString: string) => {
    setSearchString(newSearchString);
    setPage(1);
  }, 1000);

  const handleChangeVehicle = (vehicle: LegalCabinetGatewayV1Vehicle) => {
    setChangeDialogOpen(true);
    setVehicleToChange(vehicle);
  };

  const handleDeleteVehicle = (vehicle: LegalCabinetGatewayV1Vehicle) => {
    setDeleteDialogOpen(true);
    setVehicleToDelete(vehicle);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  return (
    <Paper sx={{ paddingY: "56px", paddingX: "41px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "14px"
        }}
      >
        <Typography variant='h1'>{t("common.nav.vehicles")}</Typography>
        <Button variant='contained' onClick={() => setAddDialogOpen(true)}>
          {t("common.add")}
        </Button>
      </Box>

      {(!vehiclesAreEmpty || searchString) && (
        <VehiclesSearchField
          sx={{ mt: "16px" }}
          fullWidth
          onChange={(e) => handleSearchStringChange(e.target.value)}
          onClear={() => handleSearchStringChange("")}
        />
      )}

      {isPending && <CircularProgress sx={{ mt: "16px" }} />}

      {errorToRender && (
        <Alert severity='error' sx={{ mt: "16px" }}>
          {errorToRender.message}
        </Alert>
      )}

      {!isPending && vehiclesAreEmpty && (
        <Typography variant='h2' component='p' sx={{ mt: "16px" }}>
          {t("vehicles.empty_vehicles")}
        </Typography>
      )}

      {vehicles && vehicles.length > 0 && (
        <>
          <VehiclesTable
            vehicles={vehicles}
            sx={{ mt: "16px" }}
            onChangeVehicle={handleChangeVehicle}
            onDeleteVehicle={handleDeleteVehicle}
          />

          <Pagination
            sx={{ mt: "16px" }}
            count={totalVehicles}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      <AddVehicleForm open={addDialogOpen} onClose={() => setAddDialogOpen(false)} />

      {vehicleToChange && (
        <ChangeVehicleForm
          open={changeDialogOpen}
          onClose={() => setChangeDialogOpen(false)}
          vehicle={vehicleToChange}
          TransitionProps={{ onExited: () => setVehicleToChange(null) }}
        />
      )}

      {vehicleToDelete && (
        <DeleteVehicleDialog
          open={deleteDialogOpen}
          vehicle={vehicleToDelete}
          onClose={() => setDeleteDialogOpen(false)}
          TransitionProps={{ onExited: () => setVehicleToDelete(null) }}
        />
      )}
    </Paper>
  );
};
