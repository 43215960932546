import { SxProps } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CabinetIcon } from "components/icons";
import { Paths } from "routes/paths";

import { ContactLink } from "./ContactLink";

interface CabinetButtonProps {
  sx?: SxProps;
}

export const CabinetLink: FC<CabinetButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <ContactLink component={Link} to={Paths.SignIn} underline='none' variant='layout'>
      <CabinetIcon />
      {t("common.cabinet")}
    </ContactLink>
  );
};
