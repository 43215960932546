import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const EllipsisIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='7' cy='11.5' r='1.5' fill='currentColor' />
        <circle cx='12' cy='11.5' r='1.5' fill='currentColor' />
        <circle cx='17' cy='11.5' r='1.5' fill='currentColor' />
      </svg>
    </SvgIcon>
  );
};
