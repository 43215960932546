import { Link, LinkTypeMap, styled } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ContactLinkProps {
  light?: boolean;
}

export const ContactLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "light"
})<ContactLinkProps>(({ light }) => ({
  display: "flex",
  alignItems: "center",
  gap: "14px",
  "&:hover": {
    backgroundColor: "transparent",
    ".MuiSvgIcon-root": {
      color: light ? "#FFFFFF" : "#637381",
      backgroundColor: light ? "#748594" : "#FFFFFF"
    }
  },
  "&:active": {
    ".MuiSvgIcon-root": {
      color: "#FFFFFF",
      backgroundColor: "#869BAE"
    }
  },
  ".MuiSvgIcon-root": {
    color: light ? "#637381" : "#FFFFFF",
    backgroundColor: light ? "#FFFFFF" : "#748594",
    borderRadius: "50%",
    width: "24px",
    height: "24px"
  }
})) as OverridableComponent<LinkTypeMap<ContactLinkProps>>;
