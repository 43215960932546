import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requests } from "api";

import { COMPANY_DETAILS_QUERY_KEY } from "./useCompanyDetailsQuery";

export const useEditCompanyDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requests.legalCabinetGatewayEditCompany.bind(requests),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: COMPANY_DETAILS_QUERY_KEY })
  });
};
