import { Autocomplete, AutocompleteProps } from "@mui/material";
import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StyledTextField, StyledTextFieldProps } from "./StyledTextField";

export type ControlledAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType<any, keyof React.JSX.IntrinsicElements> = "div",
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  "renderInput" | "onChange"
> &
  Omit<ControllerProps<TFieldValues, TName>, "render"> & {
    TextFieldProps?: StyledTextFieldProps;
  };

export const ControlledAutocomplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType<any, keyof React.JSX.IntrinsicElements> = "div",
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: ControlledAutocompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent,
    TFieldValues,
    TName
  >
) => {
  const {
    name,
    control,
    defaultValue,
    disabled,
    rules,
    shouldUnregister,
    TextFieldProps,
    ...autocompleteProps
  } = props;
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;

        return (
          <Autocomplete
            renderInput={(params) => (
              <StyledTextField
                {...TextFieldProps}
                {...params}
                inputRef={ref}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
            value={value}
            onChange={(_, data) => onChange(data)}
            loadingText={t("common.placeholders.autocomplete_loading")}
            noOptionsText={t("common.placeholders.autocomplete_no_options")}
            {...autocompleteProps}
          />
        );
      }}
    />
  );
};
