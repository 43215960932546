import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const TelegramIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.5003 15.5693C11.2735 15.7779 11.0506 15.9822 10.8284 16.1877C10.4793 16.5106 10.1294 16.8328 9.78243 17.1577C9.68347 17.2505 9.58033 17.3188 9.43752 17.2548C9.29931 17.1927 9.29806 17.0752 9.30766 16.9523C9.37447 16.0777 9.43711 15.2027 9.50934 14.3286C9.51477 14.2614 9.56864 14.184 9.62417 14.1357C10.7708 13.1373 11.9212 12.1426 13.0707 11.147C13.7835 10.5298 14.4959 9.91214 15.2082 9.29492C15.2425 9.26506 15.2859 9.23834 15.3059 9.20141C15.3235 9.16802 15.3327 9.10516 15.3126 9.0863C15.2846 9.05997 15.2245 9.05133 15.1802 9.05565C15.1427 9.05919 15.1076 9.09101 15.0721 9.11144C12.6039 10.5313 10.1357 11.9508 7.66915 13.3731C7.57144 13.4293 7.4896 13.4399 7.38062 13.4045C6.39308 13.0839 5.40346 12.7688 4.41426 12.4525C4.12614 12.3598 4.00296 12.2317 4.00004 12.0192C3.99753 11.8106 4.1132 11.6805 4.38795 11.5815C6.52879 10.8099 8.66921 10.0383 10.81 9.26663C12.839 8.53508 14.8687 7.80549 16.8968 7.07119C17.1983 6.96197 17.4789 6.96668 17.7311 7.16823C17.9829 7.36939 18.0447 7.62869 17.97 7.9265C17.8994 8.20741 17.8372 8.49029 17.7712 8.77199C17.0985 11.6436 16.4258 14.5152 15.7531 17.3868C15.7431 17.4296 15.7327 17.4728 15.7222 17.5156C15.6087 17.9785 15.1293 18.1451 14.7239 17.8586C14.191 17.4823 13.6612 17.1019 13.1296 16.7236C12.6315 16.3688 12.1333 16.014 11.6352 15.6597C11.5942 15.6306 11.5516 15.6039 11.4999 15.5693H11.5003Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
