import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const PARKING_FILTER_QUERY_KEY = ["parking_filter"];

export const useParkingFilterQuery = (enabled: boolean) =>
  useQuery({
    queryKey: PARKING_FILTER_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayGetParkingZones({}),
    staleTime: 0,
    gcTime: 0,
    enabled
  });
