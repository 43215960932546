import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const CabinetIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_26_88)'>
          <path
            d='M12.1604 20C9.67713 19.9303 7.71607 19.0833 6.18654 17.2639C5.98995 17.0298 5.94985 16.7989 6.061 16.5116C6.7144 14.8208 7.87822 13.6299 9.52152 12.988C11.8771 12.0678 14.0997 12.38 16.1135 13.9618C16.9652 14.631 17.5632 15.5065 17.9459 16.5362C18.0457 16.8047 18.0078 17.0258 17.8265 17.2456C16.6679 18.6509 15.1972 19.5073 13.4388 19.8445C12.9646 19.9352 12.4786 19.9625 12.16 19.9995L12.1604 20Z'
            fill='currentColor'
          />
          <path
            d='M8.86299 8.20379C8.8656 6.42992 10.2722 4.99643 12.0062 5.00001C13.7371 5.00358 15.1433 6.44779 15.1398 8.21764C15.1359 9.9875 13.7244 11.4246 11.9922 11.4219C10.2604 11.4192 8.85994 9.97901 8.86255 8.20379H8.86299Z'
            fill='currentColor'
          />
        </g>
        <defs>
          <clipPath id='clip0_26_88'>
            <rect width='12' height='15' fill='white' transform='translate(6 5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
