import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

const contactPhoneRegexp = /^[\d-]{1,30}$/;

interface ContactPhoneMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const ContactPhoneMaskInput = forwardRef<HTMLInputElement, ContactPhoneMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={contactPhoneRegexp}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export const ContactPhoneField = <T extends FieldValues>({
  rules,
  InputProps,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("company_details.labels.contact_phone");

  return (
    <ControlledTextField
      rules={{
        pattern: {
          value: contactPhoneRegexp,
          message: t("auth.errors.invalid_phone")
        },
        ...rules
      }}
      InputProps={{ inputComponent: ContactPhoneMaskInput as any, ...InputProps }}
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      placeholder={ariaLabel}
      defaultIsValid
      {...props}
    />
  );
};
