import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

export const FeedbackTextField = <T extends FieldValues>(props: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  return (
    <ControlledTextField
      rules={{
        required: {
          value: true,
          message: t("common.errors.required_field")
        }
      }}
      multiline
      minRows={2}
      placeholder={t("feedback.placeholders.text")}
      label={t("feedback.labels.text")}
      inputProps={{ maxLength: 3000 }}
      forcePlaceholder
      defaultIsValid
      {...props}
    />
  );
};
