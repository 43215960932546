import { Container } from "@mui/material";
import { FC } from "react";

import { EmployeesManagement } from "../components";

export const Employees: FC = () => {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <EmployeesManagement />
    </Container>
  );
};
