import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FioField, FioFieldProps } from "components/form";

export const SupervisorFioField = <T extends FieldValues>({
  inputProps,
  ...props
}: FioFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("company_details.labels.supervisor_fio");

  return (
    <FioField
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      placeholder={ariaLabel}
      {...props}
    />
  );
};
