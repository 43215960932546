import { East } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { FC, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { FilledIconButton } from "components/buttons";
import { StyledTextField } from "components/form";

interface PageMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PageMaskInput = forwardRef<HTMLInputElement, PageMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const calcInputWidth = (value: string) => {
  const DEFAULT_INPUT_WIDTH = 90;
  const CHAR_WIDTH = 9;

  return DEFAULT_INPUT_WIDTH + (value.length - 1) * CHAR_WIDTH;
};

interface PageTextFieldProps {
  lastPage: number;
  page: number;
  onPageChange: (newPage: number) => void;
}

export const PageTextField: FC<PageTextFieldProps> = ({ lastPage, page, onPageChange }) => {
  const { t } = useTranslation();

  const [shouldResetValue, setShouldResetValue] = useState<boolean>(true);
  const [value, setValue] = useState<string>("");

  if (shouldResetValue) {
    setValue(page.toString());
    setShouldResetValue(false);
  }

  const inputWidth = calcInputWidth(value);

  const handleBlur = () => {
    const newPage = parseInt(value);

    if (Number.isNaN(newPage) || newPage < 1) {
      onPageChange(1);
    } else if (newPage > lastPage) {
      onPageChange(lastPage);
    } else {
      onPageChange(newPage);
    }

    setShouldResetValue(true);
  };

  const endAdornment = (
    <InputAdornment position='end'>
      <FilledIconButton disableRipple>
        <East />
      </FilledIconButton>
    </InputAdornment>
  );

  return (
    <StyledTextField
      sx={{ width: `${inputWidth}px` }}
      InputProps={{
        inputComponent: PageMaskInput as any,
        endAdornment
      }}
      inputProps={{ "aria-label": t("common.pagination.page_input") }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};
