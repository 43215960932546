import { Button, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DropDownIcon } from "components/icons";
import { useRouteMatch } from "hooks";

import { NavItemProps } from "./NavItem";

export interface ParentNavItemProps extends NavItemProps {
  children: NavItemProps[];
}

export const ParentNavItem: FC<ParentNavItemProps> = ({ children, translateKey }) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const routeMatch = useRouteMatch(children.map(({ path }) => path));
  const activeItemPath = routeMatch?.pathname;

  const closeMenu = () => setMenuAnchor(null);

  return (
    <>
      <Button
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        sx={{
          fontSize: 14,
          color: activeItemPath ? "#151828" : "#637381",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "unset",
            color: "#151828"
          }
        }}
        disableRipple
        endIcon={<DropDownIcon />}
      >
        {t(translateKey)}
      </Button>

      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        MenuListProps={{ sx: { paddingY: 1.25 } }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px -4px 24px 0px #00000021",
              border: "1px solid #E9EDF4",
              borderRadius: "6px"
            }
          }
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {children.map((child) => (
          <MenuItem
            key={child.path}
            to={child.path}
            component={Link}
            onClick={closeMenu}
            selected={activeItemPath === child.path}
            disableRipple
            sx={{
              fontSize: 14,
              color: "#637381",
              textTransform: "none",
              paddingX: 2.25,
              paddingY: 1.25,
              "&:hover, &.Mui-selected": {
                backgroundColor: "unset",
                color: "#151828"
              },
              "&.Mui-selected": {
                "&:hover": {
                  backgroundColor: "unset"
                }
              }
            }}
          >
            {t(child.translateKey)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
