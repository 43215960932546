import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const CONFIG_QUERY_KEY = ["config"];

export const useConfigQuery = () =>
  useQuery({
    queryKey: CONFIG_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayGetSystemSettings(),
    staleTime: Infinity,
    gcTime: Infinity
  });
