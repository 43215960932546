import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const COMPANY_DETAILS_QUERY_KEY = ["company_details"];

export const useCompanyDetailsQuery = () => {
  return useQuery({
    queryKey: COMPANY_DETAILS_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayGetCompany({})
  });
};
