import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextFieldProps } from "components/form";

import { NoteField } from "./NoteField";

export const LegalAddressField = <T extends FieldValues>({
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("company_details.labels.legal_address");

  return (
    <NoteField
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      placeholder={ariaLabel}
      {...props}
    />
  );
};
