import { Box, Container } from "@mui/material";
import { FC } from "react";

import { useAuthContext } from "features/auth";

import { CabinetLink } from "./CabinetLink";
import { Contacts } from "./Contacts";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { SignOutButton } from "./SignOutButton";

export const Top: FC = () => {
  const { isSignedIn } = useAuthContext();

  return (
    <Box sx={{ backgroundColor: "#637381" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          minHeight: "45px",
          padding: "10px",
          "@media (min-width: 0)": {
            padding: "10px"
          }
        }}
      >
        <Contacts />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            columnGap: "80px",
            rowGap: "10px"
          }}
        >
          {isSignedIn ? <SignOutButton /> : <CabinetLink />}
          <LanguageSwitcher />
        </Box>
      </Container>
    </Box>
  );
};
