import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1VehicleType } from "api/generated";
import { setServerError } from "utils/setServerError";

import { useCreateVehicleMutation } from "../hooks";
import { mapVehicleFormToServer } from "../services";

import { VehicleForm, VehicleFormInputs, VehicleFormProps } from "./VehicleForm";

interface AddVehicleFormProps {
  open: boolean;
  onClose: () => void;
}

export const AddVehicleForm: FC<AddVehicleFormProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const form = useForm<VehicleFormInputs>({
    defaultValues: {
      label: "",
      type: LegalCabinetGatewayV1VehicleType.VEHICLE_TYPE_CAR,
      vrp: "",
      foreignOrSpecial: false,
      driverPhones: [{ value: "" }],
      vrc: ""
    }
  });

  const { mutate, isPending } = useCreateVehicleMutation();

  const handleSubmit: VehicleFormProps["onSubmit"] = (inputs) =>
    mutate(mapVehicleFormToServer(inputs), {
      onSuccess: ({ data: { error } }) => {
        if (error) {
          setServerError(form.setError, t, { message: error.message });
        } else {
          onClose();
        }
      },
      onError: (error) => setServerError(form.setError, t, { message: error?.message })
    });

  return (
    <VehicleForm
      title={t("vehicles.add_vehicle")}
      open={open}
      form={form}
      onClose={isPending ? undefined : onClose}
      onSubmit={handleSubmit}
      disabled={isPending}
      TransitionProps={{ onExited: () => form.reset() }}
    />
  );
};
