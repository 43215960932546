import { isValid } from "date-fns";
import { Control, FieldValues, Path, SetFieldValue, UseFormGetValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledDatePicker } from "components/form";

interface PeriodStartProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  setValue: SetFieldValue<T>;
  periodEndName: Path<T>;
  getValues: UseFormGetValues<T>;
}

export const PeriodStartFilter = <T extends FieldValues>({
  control,
  name,
  setValue,
  periodEndName,
  getValues
}: PeriodStartProps<T>) => {
  const { t } = useTranslation();

  const handleAccept = () => {
    let periodStart = getValues(name) as Date | null;
    const periodEnd = getValues(periodEndName) as Date | null;
    const today = new Date();

    if (isValid(periodStart) && (periodStart as Date) > today) {
      setValue(name, today);
      periodStart = today;
    }

    if (isValid(periodStart) && isValid(periodEnd) && (periodStart as Date) > (periodEnd as Date)) {
      setValue(periodEndName, periodStart);
    }
  };

  return (
    <ControlledDatePicker
      control={control}
      name={name}
      onAccept={handleAccept}
      TextFieldProps={{
        sx: { gridArea: "periodStart" },
        onBlur: handleAccept
      }}
      label={t("history.labels.period_start")}
      disableFuture
    />
  );
};
