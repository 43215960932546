import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

export const SupervisorPositionField = <T extends FieldValues>({
  rules,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("company_details.labels.supervisor_position");

  return (
    <ControlledTextField
      rules={{
        maxLength: {
          value: 150,
          message: t("common.errors.max_length", { count: 150 })
        },
        ...rules
      }}
      inputProps={{ "aria-label": ariaLabel, maxLength: 150, ...inputProps }}
      placeholder={ariaLabel}
      defaultIsValid
      {...props}
    />
  );
};
