import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const PROFILE_QUERY_KEY = ["profile"];

export const useProfileQuery = () =>
  useQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayProfile(),
    staleTime: Infinity,
    gcTime: Infinity
  });
