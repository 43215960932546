/**
 * регулярки которые приходят с бэка, не кушаются данной либой, нужно их парсить и строить корректный массив
 */
export const parseRegexpToMask = (mask: RegExp | string) => {
  // при инициализации, маски нет, и если нет дефолтной, то не работает корректно
  if (!mask) return [{ mask: "*", placeholderChar: "" }];

  const maskArr: any = []; // поставил такой тип, потому что возникла путаница с MaskType
  // заменяем нюансы на регулярки, что бы было удобнее с ними работать
  const parseVrpRegexp = mask
    .toString()
    .replace(/[\^$()]/g, "")
    .replace("\\d", "[0-9]")
    .replace("][", "]{1}[")
    .replace("\\S", "[\\S]")
    .split("|");

  parseVrpRegexp.forEach((element: string) => {
    let iMask = "";
    let iDefinitions = {};
    let prevIncludes = "";
    const find = element.match(new RegExp("\\[(.*?)\\]|{(.*?)}", "g"));
    find &&
      find.forEach((e: string) => {
        e.includes("[0") && (prevIncludes = "[0");
        if (e.includes("[\\S]")) {
          prevIncludes = "[\\S]";
        }
        if (e.includes("[") && !e.includes("[0") && !e.includes("[\\S]")) {
          prevIncludes = "[";
          iDefinitions = { ...iDefinitions, a: new RegExp(e) };
        }

        if (e.includes("{")) {
          const m = e.match(/(\{*(\d+)*,*(\d+)*\})/);
          if (m) {
            new Array(+m[2]).fill(0).forEach((_z) => {
              prevIncludes === "[\\S]" && (iMask += "*");
              prevIncludes === "[0" && (iMask += "0");
              prevIncludes !== "[0" && prevIncludes === "[" && (iMask += "a");
            });
            m[3] &&
              new Array(+m[3] - +m[2]).fill(0).forEach((_z) => {
                prevIncludes === "[\\S]" && (iMask += "[*]");
                prevIncludes === "[0" && (iMask += "[0]");
                prevIncludes !== "[0" && prevIncludes === "[" && (iMask += "[a]");
              });
          }
        }
      });
    maskArr.push({
      mask: iMask,
      definitions: iDefinitions
    });
  });

  return maskArr;
};
