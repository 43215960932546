import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { requests } from "api";
import { useCountDown } from "hooks";

export const useGetPinCodeMutation = () => {
  const {
    start: startRepeatCountDown,
    reset: resetRepeatCountDown,
    seconds: repeatSeconds
  } = useCountDown(120);

  const [isRepeat, setIsRepeat] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: requests.legalCabinetGatewayGetPinCode,
    onSuccess: () => {
      startRepeatCountDown();
      setIsRepeat(true);
    }
  });

  return {
    mutation,
    resetRepeatCountDown,
    repeatSeconds,
    isRepeat
  };
};
