import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC } from "react";
import { SubmitHandler, UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1VehicleType } from "api/generated";
import { CloseDialogButton } from "components/buttons";
import { ControlledSwitch } from "components/form";

import { DriverPhoneField } from "./DriverPhoneField";
import { VehicleNameField } from "./VehicleNameField";
import { VehicleTypeSelect } from "./VehicleTypeSelect";
import { VrcField } from "./VrcField";
import { VrpField } from "./VrpField";

export interface VehicleFormInputs {
  type: LegalCabinetGatewayV1VehicleType;
  foreignOrSpecial: boolean;
  vrp: string;
  label: string;
  vrc: string;
  driverPhones: {
    value: string;
  }[];
}

export interface VehicleFormProps {
  disabled?: boolean;
  title?: string;
  open: boolean;
  onClose?: () => void;
  onSubmit?: SubmitHandler<VehicleFormInputs>;
  form: UseFormReturn<VehicleFormInputs>;
  TransitionProps?: TransitionProps;
}

export const VehicleForm: FC<VehicleFormProps> = ({
  disabled,
  title,
  open,
  onClose,
  onSubmit,
  form: {
    control,
    trigger,
    clearErrors,
    watch,
    resetField,
    handleSubmit,
    formState: { isValid, errors }
  },
  TransitionProps
}) => {
  const { t } = useTranslation();

  const { fields, remove, insert } = useFieldArray({ control, name: "driverPhones" });
  const showDeletePhoneButton = fields.length > 1;
  const showAddPhoneButton = fields.length < 10;

  const serverErrorMessage = errors.root?.serverError?.message;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ component: "form", onSubmit: onSubmit && handleSubmit(onSubmit) }}
      TransitionProps={TransitionProps}
    >
      <CloseDialogButton onClick={onClose} />

      {title && <DialogTitle variant='h2'>{title}</DialogTitle>}

      <DialogContent>
        {serverErrorMessage && (
          <Alert severity='error' sx={{ mb: "16px" }}>
            {serverErrorMessage}
          </Alert>
        )}

        <VrpField
          fullWidth
          control={control}
          name='vrp'
          vehicleType={watch("type")}
          foreign={watch("foreignOrSpecial")}
          onClear={() => resetField("vrp", { defaultValue: "" })}
          disabled={disabled}
        />

        <ControlledSwitch
          sx={{ mt: "16px" }}
          control={control}
          name='foreignOrSpecial'
          label={t("vehicles.labels.foreign_or_special")}
          rules={{
            onChange: (e) => !e?.target?.value && resetField("vrp", { defaultValue: "" })
          }}
          disabled={disabled}
        />

        <VehicleNameField
          sx={{ mt: "16px" }}
          fullWidth
          control={control}
          name='label'
          onClear={() => resetField("label", { defaultValue: "" })}
          disabled={disabled}
        />

        <VehicleTypeSelect
          sx={{ mt: "32px" }}
          control={control}
          name='type'
          fullWidth
          rules={{
            onChange: () => resetField("vrp", { defaultValue: "" })
          }}
          disabled={disabled}
        />

        <VrcField
          sx={{ mt: "32px" }}
          fullWidth
          control={control}
          name='vrc'
          onClear={() => resetField("vrc", { defaultValue: "" })}
          disabled={disabled}
        />

        {fields.map((field, index) => (
          <DriverPhoneField
            index={index}
            key={field.id}
            sx={{ mt: index === 0 ? "32px" : "16px" }}
            control={control}
            name={`driverPhones.${index}.value`}
            trigger={trigger}
            clearErrors={clearErrors}
            showAddButton={showAddPhoneButton && index < 9}
            showDeleteButton={showDeletePhoneButton}
            onDelete={() => remove(index)}
            onAdd={() => insert(index + 1, { value: "" })}
            disabled={disabled}
          />
        ))}
      </DialogContent>

      <DialogActions>
        <Button variant='contained' type='submit' disabled={disabled || !isValid}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
