import { Alert, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCompanyDetailsQuery } from "../hooks";

import { DetailsForm } from "./DetailsForm";
import { DetailsList } from "./DetailsList";

export const DetailsManagement: FC = () => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useCompanyDetailsQuery();
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false);

  const errorToRender = error || data?.data?.error;
  const company = data?.data?.company;

  return (
    <Paper sx={{ paddingY: "56px", paddingX: "41px" }}>
      <Typography variant='h1'>{t("common.nav.company_details")}</Typography>

      {errorToRender && (
        <Alert severity='error' sx={{ mt: 2 }}>
          {errorToRender?.message || t("common.errors.unknown_error")}
        </Alert>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DetailsList company={company} />

          <Button variant='contained' onClick={() => setFormIsOpen(true)} sx={{ mt: 2 }}>
            {t("common.change")}
          </Button>

          <DetailsForm open={formIsOpen} company={company} onClose={() => setFormIsOpen(false)} />
        </>
      )}
    </Paper>
  );
};
