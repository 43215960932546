import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { useRef } from "react";
import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StyledTextField, StyledTextFieldProps } from "./StyledTextField";

export type ControlledDatePickerProps<
  TFieldValues extends FieldValues,
  TInputDate,
  TDate,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> &
  Omit<DatePickerProps<TInputDate, TDate>, "renderInput" | "onChange" | "value"> & {
    TextFieldProps?: StyledTextFieldProps;
  };

export const ControlledDatePicker = <
  TFieldValues extends FieldValues,
  TInputDate,
  TDate,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,
  TextFieldProps,
  ...DatePickerProps
}: ControlledDatePickerProps<TFieldValues, TInputDate, TDate, TName>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => {
        const { ref: inputRef, ...restField } = field;

        return (
          <DatePicker
            {...DatePickerProps}
            inputRef={inputRef}
            renderInput={(params) => (
              <StyledTextField
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...TextFieldProps}
                {...params}
                ref={ref}
                inputProps={{
                  ...params?.inputProps,
                  placeholder: t("common.placeholders.date_picker")
                }}
              />
            )}
            PopperProps={{
              anchorEl: ref.current
            }}
            {...restField}
          />
        );
      }}
    />
  );
};
