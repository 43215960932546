import { useMutation } from "@tanstack/react-query";

import { requests } from "api";
import { convertFileToUpload } from "services/convertFileToUpload";

const uploadFile = async (file: File) => {
  const convertedFile = await convertFileToUpload(file);
  return requests.legalCabinetGatewayUploadFile(convertedFile);
};

export const useUploadFileMutation = () =>
  useMutation({
    mutationFn: uploadFile
  });
