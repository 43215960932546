import { Paths } from "routes/paths";

import { NavItemProps } from "./NavItem";
import { ParentNavItemProps } from "./ParentNavItem";

export const navItems: (ParentNavItemProps | NavItemProps)[] = [
  {
    translateKey: "common.nav.balance",
    path: Paths.Balance
  },
  {
    translateKey: "common.nav.vehicles",
    path: Paths.Vehicles
  },
  {
    translateKey: "common.nav.profile",
    path: Paths.Profile,
    children: [
      {
        translateKey: "common.nav.employees",
        path: Paths.Employees
      },
      {
        translateKey: "common.nav.company_details",
        path: Paths.CompanyDetails
      }
    ]
  },
  {
    translateKey: "common.nav.fines",
    path: Paths.Fines,
    disabled: true
  },
  {
    translateKey: "common.nav.history",
    path: Paths.History
  },
  {
    translateKey: "common.feedback",
    path: Paths.Feedback
  }
];
