import { QueryClient } from "@tanstack/react-query";

import { requests } from "api";
import { CONFIG_QUERY_KEY } from "hooks";

export const prefetchConfigQuery = (queryClient: QueryClient) =>
  queryClient.prefetchQuery({
    queryKey: CONFIG_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayGetSystemSettings(),
    staleTime: Infinity,
    gcTime: Infinity
  });
