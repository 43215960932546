import { useContext } from "react";

import { AuthContext, AuthContextValue } from "../context";

export const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within a AuthContext");
  }

  return context;
};
