import { IconButtonProps, SxProps } from "@mui/material";
import { Box } from "@mui/system";
import { Control, FieldValues, Path, UseFormClearErrors, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteIconButton, PlusIconButton } from "components/buttons";
import { PhoneField } from "components/form";

interface DriverPgoneFieldProps<T extends FieldValues> {
  index: number;
  sx?: SxProps;
  control: Control<T>;
  name: Path<T>;
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
  showDeleteButton?: boolean;
  showAddButton?: boolean;
  onDelete?: IconButtonProps["onClick"];
  onAdd?: IconButtonProps["onClick"];
  disabled?: boolean;
}

export const DriverPhoneField = <T extends FieldValues>({
  index,
  sx,
  control,
  name,
  trigger,
  clearErrors,
  showDeleteButton,
  showAddButton,
  onDelete,
  onAdd,
  disabled
}: DriverPgoneFieldProps<T>) => {
  const { t } = useTranslation();

  const driverNumber = index + 1;

  const ariaLabel = t("vehicles.labels.drivers_phone");
  const deleteAriaLabel = `${t("vehicles.labels.delete_driver_phone")} ${driverNumber}`;
  const addAriaLabel = `${t("vehicles.labels.add_driver_phone")} ${driverNumber}`;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "top",
        gap: "12px",
        ...sx
      }}
    >
      <PhoneField
        control={control}
        name={name}
        trigger={trigger}
        clearErrors={clearErrors}
        fullWidth
        placeholder={ariaLabel}
        inputProps={{ "aria-label": `${ariaLabel} ${index + 1}` }}
        disabled={disabled}
      />

      {showDeleteButton && (
        <DeleteIconButton
          aria-label={deleteAriaLabel}
          sx={{ mt: "9px" }}
          disabled={disabled}
          onClick={onDelete}
        />
      )}

      {showAddButton && (
        <PlusIconButton
          aria-label={addAriaLabel}
          sx={{ mt: "9px" }}
          disabled={disabled}
          onClick={onAdd}
        />
      )}
    </Box>
  );
};
