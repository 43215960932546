import { Box } from "@mui/material";
import { FC } from "react";

import { useAuthContext } from "features/auth";

import { CommonFooter } from "./CommonFooter";
import { FooterNav } from "./FooterNav";

export const Footer: FC = () => {
  const { isSignedIn } = useAuthContext();

  return (
    <Box
      component='footer'
      sx={{
        backgroundColor: "#637381",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: isSignedIn ? "68px" : "82px",
        pb: "76px"
      }}
    >
      {isSignedIn && <FooterNav />}
      <CommonFooter />
    </Box>
  );
};
