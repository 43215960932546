import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface PageButtonsProps {
  lastPage: number;
  page: number;
  onPageChange: (newPage: number) => void;
}

export const PageButtons: FC<PageButtonsProps> = ({ page, lastPage, onPageChange }) => {
  const { t } = useTranslation();

  const previousPageDisabled = page === 1;
  const nextPageDisabled = page === lastPage;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap"
      }}
    >
      <IconButton
        aria-label={t("common.pagination.first_page")}
        disabled={previousPageDisabled}
        onClick={() => onPageChange(1)}
      >
        <FirstPage />
      </IconButton>

      <IconButton
        aria-label={t("common.pagination.previous_page")}
        disabled={previousPageDisabled}
        onClick={() => onPageChange(page - 1)}
      >
        <ChevronLeft />
      </IconButton>

      <IconButton
        aria-label={t("common.pagination.next_page")}
        disabled={nextPageDisabled}
        onClick={() => onPageChange(page + 1)}
      >
        <ChevronRight />
      </IconButton>

      <IconButton
        aria-label={t("common.pagination.last_page")}
        disabled={nextPageDisabled}
        onClick={() => onPageChange(lastPage)}
      >
        <LastPage />
      </IconButton>
    </Box>
  );
};
