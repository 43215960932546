import { Box, Link, LinkProps } from "@mui/material";
import { FC } from "react";

type MobileAppLinkProps = {
  imgSrc: string;
} & LinkProps;

export const MobileAppLink: FC<MobileAppLinkProps> = ({ imgSrc, ...props }) => {
  return (
    <Link {...props}>
      <Box src={imgSrc} component='img' sx={{ width: "115px", height: "33px" }} />
    </Link>
  );
};
