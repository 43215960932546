import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const InstagramIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18 8.76604V15.2345C17.985 15.3185 17.9711 15.4021 17.9554 15.4858C17.748 16.5871 17.1242 17.3586 16.0916 17.783C15.8221 17.8937 15.5215 17.9295 15.2351 18H8.76706C8.54244 17.9525 8.31161 17.9248 8.09465 17.8543C6.83274 17.4444 6.00693 16.3117 6.00328 14.9806C5.9978 12.9927 6.00036 11.0049 6.00255 9.01699C6.00328 8.37848 6.17969 7.79038 6.55772 7.27314C7.17972 6.4224 8.02526 6.00196 9.07715 6.00123C11.01 5.9994 12.9432 5.9994 14.8761 6.00269C15.0784 6.00269 15.2837 6.01182 15.482 6.04798C16.5905 6.24998 17.3645 6.879 17.7871 7.92005C17.8955 8.18707 17.931 8.48332 18 8.76604ZM12.002 9.49989C10.6302 9.4977 9.51544 10.6089 9.50083 11.9933C9.48622 13.3591 10.6214 14.4984 11.9998 14.501C13.3702 14.5035 14.4871 13.3905 14.5014 12.0072C14.5156 10.6414 13.3801 9.50209 12.002 9.49989ZM15.2457 9.49734C15.6595 9.50026 16.0014 9.16128 15.9999 8.74924C15.9981 8.34122 15.6654 8.00699 15.2552 8.00078C14.8505 7.99493 14.5101 8.3299 14.5043 8.73974C14.4981 9.15616 14.8293 9.49442 15.2461 9.49697L15.2457 9.49734Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
