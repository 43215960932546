import { Container } from "@mui/material";
import { FC } from "react";

import { CompanyInfo } from "./CompanyInfo";
import { DepartmentLink } from "./DepartmentLink";
import { MobileApps } from "./MobileApps";
import { SocialLinks } from "./SocialLinks";

export const CommonFooter: FC = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        flexWrap: "wrap",
        gap: "34px"
      }}
    >
      <CompanyInfo />
      <MobileApps />
      <SocialLinks />
      <DepartmentLink />
    </Container>
  );
};
