import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import { ErrorOption, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Company } from "api/generated";
import { CloseDialogButton } from "components/buttons";
import { CompanyNameField, InnOrBikField, EmailField } from "components/form";

import { useEditCompanyDetailsMutation } from "../hooks";

import { ContactPhoneField } from "./ContactPhoneField";
import { LegalAddressField } from "./LegalAddressField";
import { NoteField } from "./NoteField";
import { PostalAddressField } from "./PostalAddressField";
import { SupervisorFioField } from "./SupervisorFioField";
import { SupervisorPositionField } from "./SupervisorPositionField";

interface DetailsFormInputs {
  name: string;
  innOrBik: string;
  email: string;
  contactPhone: string;
  supervisorFio: string;
  supervisorPosition: string;
  note: string;
  postalAddress: string;
  legalAddress: string;
}

export interface DetailsFormProps {
  open: boolean;
  company?: LegalCabinetGatewayV1Company;
  onClose: () => void;
}

export const DetailsForm: FC<DetailsFormProps> = ({ open, company, onClose }) => {
  const { t } = useTranslation();

  const defaultValues: DetailsFormInputs = {
    contactPhone: company?.contactPhone ?? "",
    email: company?.email ?? "",
    innOrBik: company?.innOrBik ?? "",
    name: company?.name ?? "",
    note: company?.note ?? "",
    supervisorFio: company?.superviserFio ?? "",
    supervisorPosition: company?.superviserPosition ?? "",
    postalAddress: company?.postalAddress ?? "",
    legalAddress: company?.legalAddress ?? ""
  };

  const {
    control,
    formState: { errors, isValid },
    trigger,
    reset,
    setError,
    handleSubmit
  } = useForm<DetailsFormInputs>({ defaultValues });

  const { mutate: editCompanyDetails, isPending } = useEditCompanyDetailsMutation();

  const setServerError = (options: ErrorOption) => {
    setError("root.serverError", {
      ...options,
      message: options.message || t("common.errors.unknown_error")
    });
  };

  const handleSubmitValid: SubmitHandler<DetailsFormInputs> = ({
    supervisorFio,
    supervisorPosition,
    ...inputs
  }) => {
    editCompanyDetails(
      {
        superviserFio: supervisorFio,
        superviserPosition: supervisorPosition,
        ...inputs
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            setServerError({
              type: error.code,
              message: error?.message
            });
          } else {
            onClose();
          }
        },
        onError: (error) => setServerError({ message: error?.message })
      }
    );
  };

  const serverErrorMessage = errors.root?.serverError?.message;

  return (
    <Dialog
      open={open}
      PaperProps={{ component: "form" }}
      onClose={onClose}
      TransitionProps={{ onEntering: () => reset(defaultValues) }}
      onSubmit={handleSubmit(handleSubmitValid)}
    >
      <DialogTitle variant='h2'>{t("company_details.change_details")}</DialogTitle>

      <CloseDialogButton onClick={onClose} />

      <DialogContent
        sx={{
          ".MuiDialogTitle-root+&": { pt: 2.5 }
        }}
      >
        {serverErrorMessage && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {serverErrorMessage}
          </Alert>
        )}

        <CompanyNameField fullWidth control={control} name='name' disabled={isPending} />

        <InnOrBikField
          fullWidth
          sx={{ mt: 2 }}
          name='innOrBik'
          control={control}
          disabled={isPending}
        />

        <LegalAddressField
          fullWidth
          sx={{ mt: 2 }}
          name='legalAddress'
          control={control}
          disabled={isPending}
        />

        <PostalAddressField
          fullWidth
          sx={{ mt: 2 }}
          name='postalAddress'
          control={control}
          disabled={isPending}
        />

        <EmailField
          fullWidth
          sx={{ mt: 2 }}
          name='email'
          trigger={trigger}
          control={control}
          disabled={isPending}
        />

        <ContactPhoneField
          fullWidth
          sx={{ mt: 2 }}
          name='contactPhone'
          control={control}
          disabled={isPending}
        />

        <SupervisorFioField
          fullWidth
          sx={{ mt: 2 }}
          name='supervisorFio'
          control={control}
          disabled={isPending}
          trigger={trigger}
        />

        <SupervisorPositionField
          fullWidth
          sx={{ mt: 2 }}
          name='supervisorPosition'
          control={control}
          disabled={isPending}
        />

        <NoteField fullWidth sx={{ mt: 2 }} name='note' control={control} disabled={isPending} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant='outlined'>
          {t("common.cancel")}
        </Button>

        <Button variant='contained' type='submit' disabled={isPending || !isValid}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
