import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const VRP_FILTER_QUERY_KEY = ["vrp_filter"];

export const useVrpFilterQuery = (enabled: boolean) =>
  useQuery({
    queryKey: VRP_FILTER_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayVehiclesByCompanyUuid({}),
    staleTime: 0,
    gcTime: 0,
    enabled
  });
