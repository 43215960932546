export enum Paths {
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  Balance = "/balance",
  Vehicles = "/vehicles",
  Profile = "/profile",
  Employees = "/profile/employees",
  CompanyDetails = "/profile/company-details",
  Fines = "/fines",
  History = "/history",
  Feedback = "/feedback"
}
