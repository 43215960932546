import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Company } from "api/generated";

type DetailsKey = Exclude<keyof LegalCabinetGatewayV1Company, "uuid">;

interface DetailsKeysItem {
  key: DetailsKey;
  translationKey: string;
}

interface CompanyDetailsItem {
  key: string;
  label: string;
  value: string;
}

export const useCompanyDetailsList = (
  company?: LegalCabinetGatewayV1Company
): CompanyDetailsItem[] => {
  const { t } = useTranslation();

  if (!company) {
    return [];
  }

  const detailsKeys: DetailsKeysItem[] = [
    { key: "name", translationKey: "common.labels.company_name" },
    { key: "innOrBik", translationKey: "common.labels.inn_or_bik" },
    { key: "legalAddress", translationKey: "company_details.labels.legal_address" },
    { key: "postalAddress", translationKey: "company_details.labels.postal_address" },
    { key: "email", translationKey: "common.labels.email" },
    { key: "contactPhone", translationKey: "company_details.labels.contact_phone" },
    { key: "superviserFio", translationKey: "company_details.labels.supervisor_fio" },
    {
      key: "superviserPosition",
      translationKey: "company_details.labels.supervisor_position"
    },
    { key: "note", translationKey: "company_details.labels.note" }
  ];

  return detailsKeys
    .map(({ key, translationKey }) => ({
      key,
      label: t(translationKey),
      value: company[key] || ""
    }))
    .filter(({ value }) => value);
};
