import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";
import { useConfigQuery } from "hooks";
import { parseRegexpToMask } from "utils/parseRegexpToMask";

interface VrpMaskInputProps {
  vrpRegexpString?: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const VrpMaskInput = forwardRef<HTMLInputElement, VrpMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { vrpRegexpString, onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={vrpRegexpString && parseRegexpToMask(vrpRegexpString)}
        prepare={(value) => value.toUpperCase()}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

type VrpFieldProps<T extends FieldValues> = ControlledTextFieldProps<T> & {
  vehicleType?: string;
  foreign?: boolean;
};

export const VrpField = <T extends FieldValues>({
  vehicleType,
  foreign,
  rules,
  inputProps,
  InputProps,
  ...props
}: VrpFieldProps<T>) => {
  const { data } = useConfigQuery();

  const vehicle = data?.data?.vehicles?.find(({ type }) => type === vehicleType);
  const vrpRegexpString = foreign ? vehicle?.foreignVrpRegexp : vehicle?.vrpRegexp;
  const vrpRegexp = vrpRegexpString ? new RegExp(vrpRegexpString) : undefined;

  const { t } = useTranslation();

  const ariaLabel = t("vehicles.labels.vrp");

  return (
    <ControlledTextField
      rules={{
        required: {
          value: true,
          message: t("common.errors.required_field")
        },
        pattern: vrpRegexp
          ? { value: vrpRegexp, message: t("vehicles.errors.invalid_vrp") }
          : undefined,
        ...rules
      }}
      placeholder={ariaLabel}
      InputProps={{ inputComponent: VrpMaskInput as any, ...InputProps }}
      inputProps={{ "aria-label": ariaLabel, vrpRegexpString, ...inputProps }}
      isValid={({ field, fieldState }) =>
        !fieldState.invalid && Boolean(vrpRegexp?.test(field.value))
      }
      defaultIsClearable
      {...props}
    />
  );
};
