import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { useDownloadHistoryMutation } from "../hooks";
import { HistoryRequestFilters } from "../types";

interface LoadHistoryProps {
  filters: HistoryRequestFilters;
}

export const LoadHistory: FC<LoadHistoryProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { mutate, isPending } = useDownloadHistoryMutation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    mutate(filters);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "4px"
      }}
    >
      <Button variant='contained' disabled={isPending} onClick={handleClick}>
        {t("common.load")}
      </Button>

      <Tooltip title={t("history.load_tooltip")} enterTouchDelay={0}>
        <IconButton color='primary'>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
