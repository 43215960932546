import { FieldValues, RegisterOptions, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "./ControlledTextField";

type EmailFieldProps<T extends FieldValues> = ControlledTextFieldProps<T> & {
  trigger: UseFormTrigger<T>;
};

const emailRegexp = /^[\w\-_.]+@([\w\-_.]+\.)+\w+$/;
const triggerEmailRegexp = /@./;

export const EmailField = <T extends FieldValues>({
  name,
  rules,
  trigger,
  inputProps,
  ...props
}: EmailFieldProps<T>) => {
  const { t } = useTranslation();

  const handleChange: RegisterOptions["onChange"] = (e) => {
    const value = e?.target?.value ?? "";

    if (!value || triggerEmailRegexp.test(value)) {
      trigger(name);
    }
  };

  const ariaLabel = t("common.labels.email");

  return (
    <ControlledTextField
      name={name}
      rules={{
        maxLength: {
          value: 320,
          message: t("common.errors.max_length", { count: 320 })
        },
        pattern: {
          value: emailRegexp,
          message: t("auth.errors.invalid_email")
        },
        onChange: handleChange,
        ...rules
      }}
      placeholder={ariaLabel}
      inputProps={{ maxLength: 320, "aria-label": ariaLabel, ...inputProps }}
      isValid={({ field, fieldState }) => !fieldState.invalid && emailRegexp.test(field.value)}
      {...props}
    />
  );
};
