import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle
} from "@mui/material";
import { FC } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CloseDialogButton } from "components/buttons";
import { EmailField, PhoneField, FioField } from "components/form";

export type EmployeeFormInputs = {
  fio: string;
  phone: string;
  email: string;
};

export interface EmployeeFormProps {
  disabled?: boolean;
  title?: string;
  open: boolean;
  onClose?: () => void;
  onSubmit?: SubmitHandler<EmployeeFormInputs>;
  TransitionProps?: DialogProps["TransitionProps"];
  form: UseFormReturn<EmployeeFormInputs>;
}

export const EmployeeForm: FC<EmployeeFormProps> = ({
  disabled,
  title,
  open,
  onClose,
  onSubmit,
  form: {
    control,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { isValid, errors }
  },
  TransitionProps
}) => {
  const { t } = useTranslation();
  const serverErrorMessage = errors.root?.serverError?.message;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ component: "form", onSubmit: onSubmit && handleSubmit(onSubmit) }}
      TransitionProps={TransitionProps}
    >
      <CloseDialogButton onClick={onClose} />

      {title && <DialogTitle variant='h2'>{title}</DialogTitle>}

      <DialogContent>
        {serverErrorMessage && (
          <Alert severity='error' sx={{ mb: "16px" }}>
            {serverErrorMessage}
          </Alert>
        )}

        <FioField fullWidth control={control} name='fio' trigger={trigger} disabled={disabled} />

        <PhoneField
          sx={{ mt: "16px" }}
          fullWidth
          name='phone'
          control={control}
          clearErrors={clearErrors}
          trigger={trigger}
          disabled={disabled}
        />

        <EmailField
          sx={{ mt: "16px" }}
          fullWidth
          name='email'
          trigger={trigger}
          control={control}
          disabled={disabled}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant='contained' type='submit' disabled={disabled || !isValid}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
