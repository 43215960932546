import { axiosInstance, interceptors, requests } from "api";

interface SignInOptions {
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: string | number;
}

type onSignedInChange = (isSignedIn: boolean) => void;

export enum TokensLocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  EXPIRES_IN = "expiresIn"
}

// The class is used to incapsulate auth tokens management.
// It sets required axios interceptors on sign in and ejects them on sign out.
class Tokens {
  private refreshTokensPromise: Promise<void> | null = null;
  private requestInterceptorId: number | null = null;
  private responseInterceptorId: number | null = null;
  public onSignedInChange: onSignedInChange | null = null;

  public constructor() {
    if (this.isSignedIn) {
      this.setRequestInterceptor();
      this.setResponseInterceptor();
    } else {
      this.signOut();
    }
  }

  public get accessToken() {
    return localStorage.getItem(TokensLocalStorageKeys.ACCESS_TOKEN) ?? "";
  }

  private set accessToken(token: string) {
    localStorage.setItem(TokensLocalStorageKeys.ACCESS_TOKEN, token);
  }

  public get refreshToken() {
    return localStorage.getItem(TokensLocalStorageKeys.REFRESH_TOKEN) ?? "";
  }

  private set refreshToken(token: string) {
    localStorage.setItem(TokensLocalStorageKeys.REFRESH_TOKEN, token);
  }

  public get expiresIn(): number {
    const localStorageValue = localStorage.getItem(TokensLocalStorageKeys.EXPIRES_IN);
    return localStorageValue ? parseInt(localStorageValue) : 0;
  }

  private set expiresIn(sec: number | string) {
    const secNumber = typeof sec === "string" ? parseInt(sec) : sec;
    const dateNowSeconds = Date.now() / 1000;
    const expiresIn = dateNowSeconds + secNumber;
    localStorage.setItem(TokensLocalStorageKeys.EXPIRES_IN, expiresIn.toString());
  }

  public get isSignedIn() {
    return Boolean(this.accessToken);
  }

  public signIn({ accessToken, refreshToken, expiresIn }: SignInOptions) {
    if (!accessToken) {
      this.signOut();
      return;
    }

    this.accessToken = accessToken;
    this.refreshToken = refreshToken ?? "";
    this.expiresIn = expiresIn ?? 0;

    this.setRequestInterceptor();
    this.setResponseInterceptor();

    if (this.onSignedInChange) {
      this.onSignedInChange(true);
    }
  }

  public signOut() {
    localStorage.removeItem(TokensLocalStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(TokensLocalStorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(TokensLocalStorageKeys.EXPIRES_IN);

    this.ejectRequestInterceptor();
    this.ejectResponseInterceptor();

    if (this.onSignedInChange) {
      this.onSignedInChange(false);
    }
  }

  private async getAndRefreshAccessToken() {
    if (!this.accessToken) {
      return this.accessToken;
    }

    const nowDateInSec = Date.now() / 1000;

    if (this.expiresIn - nowDateInSec < 60) {
      await this.refreshTokens();
    }

    return this.accessToken;
  }

  private async refreshTokens() {
    // If refresh request is already sent, the promise is returned to prevent double request.
    if (this.refreshTokensPromise) {
      return this.refreshTokensPromise;
    }

    const refreshTokensPromise = async () => {
      try {
        const {
          data: { refreshToken, accessToken, expiresIn }
        } = await requests.legalCabinetGatewayRefreshToken({
          refreshToken: this.refreshToken
        });

        if (accessToken) {
          this.accessToken = accessToken;
          this.refreshToken = refreshToken ?? "";
          this.expiresIn = expiresIn ?? 0;
        } else {
          this.signOut();
        }
      } catch (e) {
        this.signOut();
      } finally {
        this.refreshTokensPromise = null;
      }
    };

    this.refreshTokensPromise = refreshTokensPromise();
    return this.refreshTokensPromise;
  }

  private ejectRequestInterceptor() {
    if (this.requestInterceptorId !== null) {
      interceptors.request.eject(this.requestInterceptorId);
      this.requestInterceptorId = null;
    }
  }

  private setRequestInterceptor() {
    if (this.requestInterceptorId !== null) {
      return;
    }

    this.requestInterceptorId = interceptors.request.use(async (req) => {
      const accessToken =
        req.url === "/v1/auth/refresh-token"
          ? this.accessToken
          : await this.getAndRefreshAccessToken();

      req.headers.authorization = `Bearer ${accessToken}`;
      return req;
    });
  }

  private ejectResponseInterceptor() {
    if (this.responseInterceptorId !== null) {
      interceptors.response.eject(this.responseInterceptorId);
      this.responseInterceptorId = null;
    }
  }

  private setResponseInterceptor() {
    if (this.responseInterceptorId !== null) {
      return;
    }

    this.responseInterceptorId = interceptors.response.use(undefined, async (error) => {
      if (error.config.url === "/v1/auth/refresh-token" || error.response?.status !== 401) {
        throw error;
      }

      try {
        const accessToken = await this.getAndRefreshAccessToken();

        return axiosInstance.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            authorization: `Bearer ${accessToken}`
          }
        });
      } catch {
        throw error;
      }
    });
  }
}

export const tokens = new Tokens();
