import type { FC } from "react";

import { SignInForm, FormWrapper } from "../components";

export const SignIn: FC = () => {
  return (
    <FormWrapper>
      <SignInForm />
    </FormWrapper>
  );
};
