import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CompanyLogo } from "components/icons";

export const CompanyInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: "290px" }}>
      <CompanyLogo sx={{ color: "#FFFFFF" }} />

      {[1, 2, 3, 4].map((i) => (
        <Typography key={i} sx={{ opacity: 0.6 }} variant='layout'>
          {t(`common.footer.company_info.part_${i}`)}
        </Typography>
      ))}
    </Box>
  );
};
