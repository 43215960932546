import { List, ListItem, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Company } from "api/generated";

import { useCompanyDetailsList } from "../hooks";

interface DetailsListProps {
  company?: LegalCabinetGatewayV1Company;
}

export const DetailsList: FC<DetailsListProps> = ({ company }) => {
  const { t } = useTranslation();
  const companyDetails = useCompanyDetailsList(company);

  if (companyDetails.length === 0) {
    return (
      <Typography variant='h2' component='p'>
        {t("company_details.empty_details")}
      </Typography>
    );
  }

  return (
    <List
      sx={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        mt: 2,
        width: "100%",
        overflow: "auto"
      }}
    >
      {companyDetails.map(({ key, label, value }) => (
        <ListItem
          key={key}
          sx={{
            flexDirection: "column",
            alignItems: "start",
            padding: "0 0 3px 0",
            borderBottom: "1px solid #D3D8E0"
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "#ACB6BE"
            }}
          >
            {label}
          </Typography>
          <Typography
            sx={{
              color: "#637381",
              mt: -0.5,
              width: "100%",
              wordWrap: "break-word"
            }}
            aria-label={label}
          >
            {value}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
