import { TFunction } from "i18next";
import { ErrorOption, FieldValues, UseFormSetError } from "react-hook-form";

export interface SetServerErrorOptions<T extends FieldValues> {
  options: ErrorOption;
  t: TFunction;
  setError: UseFormSetError<T>;
}

export const setServerError = <T extends FieldValues>(
  setError: UseFormSetError<T>,
  t: TFunction,
  options: ErrorOption
): void => {
  setError("root.serverError", {
    ...options,
    message: options.message || t("common.errors.unknown_error")
  });
};
