import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { filterStringNumbers } from "utils/filterStringNumbers";
import { setServerError } from "utils/setServerError";

import { useCreateEmployeeMutation } from "../hooks";
import { EmployeeRoles } from "../types";

import { EmployeeForm, EmployeeFormInputs, EmployeeFormProps } from "./EmployeeForm";

interface AddEmployeeFormProps {
  open: boolean;
  onClose: () => void;
}

export const AddEmployeeForm: FC<AddEmployeeFormProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const form = useForm<EmployeeFormInputs>({
    defaultValues: {
      email: "",
      fio: "",
      phone: ""
    }
  });

  const { mutate, isPending } = useCreateEmployeeMutation();

  const handleSubmit: EmployeeFormProps["onSubmit"] = ({ email, fio, phone }) =>
    mutate(
      {
        email,
        fio,
        role: EmployeeRoles.REPRESENTATIVE,
        phone: filterStringNumbers(phone)
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            setServerError(form.setError, t, { message: error.message });
          } else {
            onClose();
          }
        },
        onError: (error) => setServerError(form.setError, t, { message: error?.message })
      }
    );

  return (
    <EmployeeForm
      title={t("employees.add_employee")}
      open={open}
      form={form}
      onClose={isPending ? undefined : onClose}
      onSubmit={handleSubmit}
      disabled={isPending}
      TransitionProps={{ onExited: () => form.reset() }}
    />
  );
};
