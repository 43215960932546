import { Dialog, DialogContent } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CloseDialogButton } from "components/buttons";

interface ConfirmRegistrationDialogProps {
  open: boolean;
  onClose?: VoidFunction;
}

export const ConfirmRegistrationDialog: FC<ConfirmRegistrationDialogProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "100%", maxWidth: "600px" } }}>
      <CloseDialogButton onClick={onClose} />
      <DialogContent sx={{ paddingY: "40px" }}>{t("auth.confirm_registration")}</DialogContent>
    </Dialog>
  );
};
