import { useEffect, useState } from "react";

export const useCountDown = (startSeconds = 30) => {
  if (startSeconds < 0) {
    throw new Error("startSeconds should be a positive number");
  }

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (seconds === 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [seconds]);

  const start = () => setSeconds(startSeconds);
  const reset = () => setSeconds(0);

  return { start, reset, seconds };
};
