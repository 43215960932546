import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const SearchIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.75 10.875C3.75 6.93997 6.93997 3.75 10.875 3.75C14.81 3.75 18 6.93997 18 10.875C18 14.81 14.81 18 10.875 18C6.93997 18 3.75 14.81 3.75 10.875ZM10.875 2.25C6.11154 2.25 2.25 6.11154 2.25 10.875C2.25 15.6385 6.11154 19.5 10.875 19.5C12.9865 19.5 14.9209 18.7412 16.4202 17.4815L20.4691 21.5304C20.762 21.8233 21.2369 21.8233 21.5298 21.5304C21.8227 21.2375 21.8227 20.7627 21.5298 20.4698L17.4809 16.4209C18.741 14.9215 19.5 12.9869 19.5 10.875C19.5 6.11154 15.6385 2.25 10.875 2.25Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
