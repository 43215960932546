import { TransitionProps } from "@mui/material/transitions";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LegalCabinetGatewayV1Vehicle,
  LegalCabinetGatewayV1VehicleType,
  LegalCabinetGatewayV1VrpFormat
} from "api/generated";
import { convertStringToPhone } from "utils/convertStringToPhone";
import { setServerError } from "utils/setServerError";

import { useEditVehicleMutation } from "../hooks";
import { mapVehicleFormToServer } from "../services";

import { VehicleForm, VehicleFormInputs, VehicleFormProps } from "./VehicleForm";

interface ChangeVehicleFormProps {
  open: boolean;
  vehicle: LegalCabinetGatewayV1Vehicle;
  onClose: () => void;
  TransitionProps?: TransitionProps;
}

export const ChangeVehicleForm: FC<ChangeVehicleFormProps> = ({
  vehicle,
  open,
  onClose,
  TransitionProps
}) => {
  const { t } = useTranslation();

  const form = useForm<VehicleFormInputs>({
    defaultValues: {
      label: vehicle.label ?? "",
      type: vehicle.type ?? LegalCabinetGatewayV1VehicleType.VEHICLE_TYPE_CAR,
      vrp: vehicle.vrp ?? "",
      foreignOrSpecial: vehicle.vrpFormat === LegalCabinetGatewayV1VrpFormat.VRP_FORMAT_FOREIGN,
      driverPhones:
        vehicle.driverPhones && vehicle.driverPhones.length > 0
          ? vehicle.driverPhones.map((phone) => ({
              value: phone.length === 11 ? convertStringToPhone(phone) : ""
            }))
          : [{ value: "" }],
      vrc: vehicle.sts ? `${vehicle.sts.substring(0, 2)} ${vehicle.sts.substring(2)}` : ""
    }
  });

  const { mutate, isPending } = useEditVehicleMutation();

  const handleSubmit: VehicleFormProps["onSubmit"] = (inputs) =>
    mutate(
      {
        uuid: vehicle.uuid,
        ...mapVehicleFormToServer(inputs)
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            const errorMessage =
              error.code === "vehicle_error_6"
                ? t("vehicles.errors.edit_active_session")
                : error.message;

            setServerError(form.setError, t, { message: errorMessage });
          } else {
            onClose();
          }
        },
        onError: (error) => setServerError(form.setError, t, { message: error?.message })
      }
    );

  return (
    <VehicleForm
      title={t("vehicles.change_vehicle")}
      open={open}
      form={form}
      disabled={isPending}
      onClose={isPending ? undefined : onClose}
      onSubmit={handleSubmit}
      TransitionProps={TransitionProps}
    />
  );
};
