import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ControlledTextField, ControlledTextFieldProps } from "./ControlledTextField";

const innOrBikMask = "000000000000";
const innOrBikRegexp = /^\d{12}$/;

interface InnOrBikMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InnOrBikMaskInput = forwardRef<HTMLInputElement, InnOrBikMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={innOrBikMask}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export const InnOrBikField = <T extends FieldValues>({
  rules,
  InputProps,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("common.labels.inn_or_bik");

  return (
    <ControlledTextField
      rules={{
        maxLength: {
          value: 12,
          message: t("common.errors.max_length", { count: 12 })
        },
        pattern: {
          value: innOrBikRegexp,
          message: t("auth.errors.inn_or_bik_numbers")
        },
        ...rules
      }}
      InputProps={{ inputComponent: InnOrBikMaskInput as any, ...InputProps }}
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      placeholder={ariaLabel}
      isValid={({ field, fieldState }) => !fieldState.invalid && innOrBikRegexp.test(field.value)}
      {...props}
    />
  );
};
