import { useQueryClient } from "@tanstack/react-query";
import { FC, ReactNode, createContext, useEffect, useState } from "react";

import { FEEDBACK_TOPICS_QUERY_KEY } from "features/feedback";
import { CONFIG_QUERY_KEY } from "hooks";

import { tokens, prefetchProfileQuery } from "../services";

type SignInFn = typeof tokens.signIn;
type SignOutFn = typeof tokens.signOut;

export interface AuthContextValue {
  isSignedIn: boolean;
  signIn: SignInFn;
  signOut: SignOutFn;
}

export const AuthContext = createContext<AuthContextValue | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(tokens.isSignedIn);
  const queryClient = useQueryClient();

  useEffect(() => {
    tokens.onSignedInChange = setIsSignedIn;

    return () => {
      tokens.onSignedInChange = null;
    };
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      prefetchProfileQuery(queryClient);
    } else {
      queryClient.removeQueries({
        predicate: ({ queryKey }) => {
          const valueToCompare = Array.isArray(queryKey) ? queryKey[0] : queryKey;

          return (
            valueToCompare !== CONFIG_QUERY_KEY[0] &&
            valueToCompare !== FEEDBACK_TOPICS_QUERY_KEY[0]
          );
        }
      });
    }
  }, [isSignedIn, queryClient]);

  const value: AuthContextValue = {
    isSignedIn,
    signIn: tokens.signIn.bind(tokens),
    signOut: tokens.signOut.bind(tokens)
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
