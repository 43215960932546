import { QueryClient } from "@tanstack/react-query";

import { requests } from "api";

import { PROFILE_QUERY_KEY } from "../hooks";

export const prefetchProfileQuery = (queryClient: QueryClient) =>
  queryClient.prefetchQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayProfile(),
    staleTime: Infinity,
    gcTime: Infinity
  });
