import { Box, CircularProgress, FormHelperText, SxProps } from "@mui/material";
import { FC, MouseEventHandler } from "react";

import { DeleteIconButton } from "components/buttons";

interface FilePreviewProps {
  sx?: SxProps;
  file: File;
  isPending?: boolean;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  error?: string;
}

export const FilePreview: FC<FilePreviewProps> = ({ file, sx, isPending, onDelete, error }) => {
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "128px",
          height: "128px",
          borderRadius: "6px",
          border: error ? "#FF5651 solid 2px" : undefined,
          overflow: "hidden",
          ...sx
        }}
      >
        <Box
          component='img'
          title={file.name}
          alt={file.name}
          src={URL.createObjectURL(file)}
          sx={{ width: "100%", height: "100%" }}
        />

        {isPending ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <DeleteIconButton sx={{ position: "absolute", right: 4, top: 4 }} onClick={onDelete} />
        )}
      </Box>

      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
