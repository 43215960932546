import { MenuItem } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";
import { useConfigQuery } from "hooks";

export const VehicleTypeSelect = <T extends FieldValues>({
  rules,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();
  const { data } = useConfigQuery();

  const ariaLabel = t("vehicles.labels.vehicle_type");

  return (
    <ControlledTextField
      select
      rules={{
        required: {
          value: true,
          message: t("common.errors.required_field")
        },
        ...rules
      }}
      placeholder={ariaLabel}
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      valid
      {...props}
    >
      {data?.data.vehicles?.map(({ type, title }) => (
        <MenuItem key={type} value={type}>
          {title?.ru}
        </MenuItem>
      ))}
    </ControlledTextField>
  );
};
