import { useMutation } from "@tanstack/react-query";

import { requests } from "api";

import { useAuthContext } from "./useAuthContext";

export const useConfirmPinCodeMutation = () => {
  const { signIn } = useAuthContext();

  return useMutation({
    mutationFn: requests.legalCabinetGatewayConfirmPinCode,
    onSuccess: ({ data }) => signIn(data)
  });
};
