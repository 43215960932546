import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const CheckboxIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='4' y='4' width='16' height='16' rx='3' stroke='#DDDDE1' strokeWidth='2' />
      </svg>
    </SvgIcon>
  );
};
