import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PhoneIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_25_66)'>
          <path
            d='M15.1905 18C14.5231 17.9303 13.9388 17.7564 13.3709 17.5235C12.2108 17.0481 11.1812 16.3631 10.2281 15.5585C8.91635 14.4514 7.80987 13.1703 6.96624 11.6722C6.52208 10.883 6.1852 10.0527 6.02141 9.15591C5.97205 8.88546 6.00209 8.65509 6.16338 8.42507C6.70768 7.64772 7.30062 6.91295 8.00013 6.26868C8.07523 6.19928 8.15784 6.13668 8.24295 6.0798C8.41461 5.96497 8.53441 5.9732 8.68962 6.112C8.759 6.17424 8.82588 6.24257 8.87952 6.31841C9.42704 7.09074 9.93379 7.88812 10.3411 8.74452C10.5543 9.19275 10.5317 9.27825 10.1623 9.60808C9.90625 9.83702 9.64912 10.0653 9.3902 10.291C9.32154 10.3511 9.30616 10.4094 9.33334 10.5003C9.69204 11.6926 10.364 12.6781 11.3142 13.4748C11.9569 14.0135 12.6757 14.4195 13.4882 14.6506C13.5844 14.6778 13.6455 14.6628 13.7088 14.5902C13.9195 14.3476 14.1358 14.1101 14.3472 13.8679C14.713 13.4494 14.7964 13.425 15.296 13.6661C16.126 14.0672 16.9074 14.5512 17.6556 15.0874C18.0908 15.3993 18.1065 15.5746 17.7432 15.9696C17.0658 16.7058 16.298 17.3372 15.4583 17.8802C15.3646 17.941 15.2523 17.9732 15.1908 18H15.1905Z'
            fill='currentColor'
          />
          <path
            d='M12.4282 6.8521V6.0143C15.2477 5.93918 17.9975 8.32272 17.9953 11.5587H17.1474C17.1181 10.2763 16.6736 9.15802 15.7605 8.24474C14.8468 7.33038 13.7293 6.88179 12.4282 6.8521Z'
            fill='currentColor'
          />
          <path
            d='M15.8436 11.5659H15.021C14.8558 10.0084 13.9997 9.14659 12.4304 8.97453V8.15497C14.2368 8.09845 15.8868 9.68354 15.8436 11.5659Z'
            fill='currentColor'
          />
        </g>
        <defs>
          <clipPath id='clip0_25_66'>
            <rect width='12' height='12' fill='white' transform='translate(6 6)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
