import { Box, Link } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const DepartmentLink: FC = () => {
  const { t } = useTranslation();

  return (
    <Link
      href={window.DEPARTMENT_URL || "#"}
      sx={{
        maxWidth: "225px",
        display: "flex",
        alignItems: "center",
        gap: "20px",
        mt: "12px"
      }}
      variant='layout'
      underline='none'
    >
      <Box sx={{ height: "55px" }} component='img' src='/img/department.png' />
      {t("common.footer.department")}
    </Link>
  );
};
