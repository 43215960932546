import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const CloseIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.1075 7.6928C8.71697 7.30227 8.08381 7.30227 7.69328 7.6928C7.30276 8.08332 7.30276 8.71648 7.69328 9.10701L10.5862 11.9999L7.69329 14.8929C7.30276 15.2834 7.30276 15.9166 7.69329 16.3071C8.08381 16.6976 8.71697 16.6976 9.1075 16.3071L12.0004 13.4142L14.8933 16.307C15.2838 16.6975 15.917 16.6975 16.3075 16.307C16.698 15.9165 16.698 15.2833 16.3075 14.8928L13.4146 11.9999L16.3075 9.10708C16.698 8.71656 16.698 8.08339 16.3075 7.69287C15.917 7.30234 15.2838 7.30234 14.8933 7.69287L12.0004 10.5857L9.1075 7.6928Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
