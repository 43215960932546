import { ReactNode } from "react";

import { InvalidFieldIcon, ValidFieldIcon } from "components/icons";

interface GetFieldIconOptions {
  valid?: boolean;
  error?: boolean;
  defaultIcon?: ReactNode;
}

export const getFieldIcon = ({
  defaultIcon,
  error,
  valid
}: GetFieldIconOptions): ReactNode | undefined => {
  if (error) {
    return <InvalidFieldIcon color='error' />;
  }

  if (valid) {
    return <ValidFieldIcon color='primary' />;
  }

  return defaultIcon;
};
