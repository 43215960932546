import { useQuery } from "@tanstack/react-query";

import { requests } from "api";
import { LegalCabinetGatewayV1CompanyEventsRequest } from "api/generated";

export const EVENTS_QUERY_KEY = ["events"];

const getEventsQueryKey = (body: LegalCabinetGatewayV1CompanyEventsRequest) => {
  const bodyKeys = Object.values(body).filter((value) => Boolean(value));
  return [...EVENTS_QUERY_KEY, ...bodyKeys];
};

export const useEventsQuery = (body: LegalCabinetGatewayV1CompanyEventsRequest) =>
  useQuery({
    queryKey: getEventsQueryKey(body),
    queryFn: () => requests.legalCabinetGatewayGetCompanyEvents(body)
  });
