import { Box, Button, Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import { FC, MouseEventHandler, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CancelButton, CloseDialogButton } from "components/buttons";

type DeleteDialogProps = {
  title: string;
  subTitle?: string;
  confirmTitle?: string;
  cancelTitle?: string;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  confirmDisabled?: boolean;
  children?: ReactNode;
} & DialogProps;

export const DeleteDialog: FC<DeleteDialogProps> = ({
  title,
  subTitle,
  confirmTitle,
  cancelTitle,
  onConfirm,
  onCancel,
  PaperProps,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{ ...PaperProps, sx: { maxWidth: "640px", width: "100%", ...PaperProps?.sx } }}
      {...props}
    >
      <CloseDialogButton onClick={onCancel} />
      <DialogContent sx={{ padding: "80px 100px 42px 100px" }}>
        <Typography
          variant='h2'
          component='p'
          textAlign='center'
          fontWeight={500}
          lineHeight='30px'
        >
          {title}
        </Typography>

        <Typography
          variant='body1'
          component='p'
          textAlign='center'
          fontSize='14px'
          fontWeight={400}
          color='#ACB6BE'
          mt='24px'
        >
          {subTitle ?? t("common.confirm_action")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "32px",
            mt: "44px"
          }}
        >
          <CancelButton onClick={onCancel} variant='outlined'>
            {cancelTitle || t("common.no")}
          </CancelButton>
          <Button onClick={onConfirm} color='error' variant='contained'>
            {confirmTitle || t("common.yes")}
          </Button>
        </Box>

        {children}
      </DialogContent>
    </Dialog>
  );
};
