import { Alert } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Error, LegalCabinetGatewayV1User } from "api/generated";
import { DeleteDialog } from "components/dialogs";

import { useDeleteEmployeeMutation } from "../hooks";

interface DeleteEmployeeDialogProps {
  open: boolean;
  employee: LegalCabinetGatewayV1User;
  onClose?: () => void;
  TransitionProps?: TransitionProps;
}

export const DeleteEmployeeDialog: FC<DeleteEmployeeDialogProps> = ({
  open,
  employee,
  onClose,
  TransitionProps
}) => {
  const { t } = useTranslation();

  const { mutate, isPending, error } = useDeleteEmployeeMutation();
  const [successError, setSuccessError] = useState<LegalCabinetGatewayV1Error | null>(null);

  const handleConfirm = () =>
    mutate(
      { uuid: employee.uuid },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            setSuccessError(error);
          } else {
            onClose && onClose();
          }
        }
      }
    );

  const errorToRender = error || successError;
  const onCloseInner = isPending ? undefined : onClose;

  return (
    <DeleteDialog
      open={open}
      title={t("employees.delete_employee")}
      onClose={onCloseInner}
      onCancel={onCloseInner}
      onConfirm={isPending ? undefined : handleConfirm}
      TransitionProps={TransitionProps}
    >
      {errorToRender && (
        <Alert severity='error'>{errorToRender.message || t("common.error.unknown_error")}</Alert>
      )}
    </DeleteDialog>
  );
};
