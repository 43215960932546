import { LegalCabinetGatewayV1UploadFileRequest } from "api/generated";
import {
  getBase64Content,
  fileToDataURL,
  getFileExtension,
  getFilenameWithoutExtension
} from "utils/fileUtils";

export const convertFileToUpload = async (
  file: File
): Promise<LegalCabinetGatewayV1UploadFileRequest> => {
  const dataURL = await fileToDataURL(file);
  return {
    base64Content: getBase64Content(dataURL),
    contentType: file.type,
    extension: getFileExtension(file.name),
    name: getFilenameWithoutExtension(file.name)
  };
};
