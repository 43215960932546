import type {} from "@mui/x-date-pickers/themeAugmentation";
import { createTheme } from "@mui/material";
import { ruRU } from "@mui/x-date-pickers";

import { SelectIcon } from "components/icons";

const paperShadowStyles = {
  boxShadow: "0px -4px 24px 0px #00000021",
  border: "1px solid #E9EDF4",
  borderRadius: "6px"
};

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#17BD31",
        light: "#4CDE65",
        dark: "#077B1C",
        contrastText: "#FFFFFF"
      },
      error: {
        main: "#FF5651",
        light: "#FF807D",
        dark: "#A61E1A",
        contrastText: "#FFFFFF"
      }
    },
    typography: {
      fontFamily: "Geologica, sans-serif",
      h1: {
        color: "#212B36",
        fontSize: 36,
        fontWeight: 500
      },
      h2: {
        color: "#212B36",
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 2
      },
      body1: {
        color: "#637381",
        fontSize: 15
      },
      layout: {
        color: "#FFFFFF",
        fontFamily: "Geologica, sans-serif",
        fontSize: 12,
        fontWeight: 400
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        body {
          background-color: #F7F7F8;
        }
      `
      },
      MuiLink: {
        styleOverrides: {
          underlineAlways: {
            textDecorationColor: "inherit"
          },
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "layout" && { color: "#FFFFFF" })
          })
        }
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            layout: "p"
          }
        }
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: false
        },
        styleOverrides: {
          root: ({ theme }) => ({
            maxWidth: "1170px",
            [theme.breakpoints.up("xs")]: {
              padding: "12px"
            }
          })
        }
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "content" },
            style: {
              borderRadius: 10,
              boxShadow: "-10px 20px 60px 0px #6E85A814"
            }
          }
        ],
        defaultProps: {
          variant: "content"
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 14,
            marginLeft: 3,
            marginTop: 6
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "none",
            position: "initial",
            fontSize: 12,
            "&, &.Mui-focused, &.Mui-error": {
              color: "#ACB6BE"
            }
          }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: false
        },
        styleOverrides: {
          root: {
            borderRadius: 6,
            fontSize: 14,
            color: "#212B36",
            backgroundColor: "#FCFDFE",
            height: 48,
            "label + &": {
              marginTop: 5
            },
            "&.Mui-disabled": {
              color: "#ACB6BE"
            },
            "&:hover, &.Mui-disabled": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#E9EDF4"
              }
            },
            "&.Mui-focused": {
              caretColor: "#13C296",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#7FDB8F",
                borderWidth: "1px"
              }
            },
            "&.Mui-error": {
              backgroundColor: "#FFFBFB",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#FFAAA8"
              }
            },
            "&.MuiInputBase-multiline": {
              height: "initial",
              padding: "0 14px 0 0"
            }
          },
          notchedOutline: {
            borderColor: "#E9EDF4"
          },
          input: {
            "&:-webkit-autofill": {
              backgroundClip: "text"
            },
            padding: "13px 20px",
            ".MuiInputBase-adornedEnd &": {
              paddingRight: 0
            },
            ".MuiInputBase-adornedStart &": {
              paddingLeft: 0
            },
            "&::placeholder": {
              color: "#ACB6BE",
              opacity: 1,
              fontWeight: 400,
              fontSize: 14,
              WebkitTextFillColor: "#ACB6BE"
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#212B36",
            fontSize: 15
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 13
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: paperShadowStyles,
          list: {
            padding: "10px 0"
          }
        }
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            "&:hover, &.Mui-selected, &.Mui-selected:hover": {
              backgroundColor: "#F9FAFD"
            }
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: SelectIcon
        },
        styleOverrides: {
          icon: {
            right: 20,
            top: 12
          },
          iconOpen: {
            color: "#17BD31"
          },
          select: {
            "&.MuiOutlinedInput-input": {
              paddingRight: 50
            }
          }
        }
      },
      MuiAutocomplete: {
        defaultProps: {
          forcePopupIcon: true,
          popupIcon: <SelectIcon />
        },
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-root.MuiAutocomplete-inputRoot": {
              ".MuiAutocomplete-endAdornment": {
                right: 20
              }
            }
          },
          paper: paperShadowStyles
        }
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: paperShadowStyles
        }
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            borderRadius: 6,
            paddingTop: 12,
            paddingBottom: 12,
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: 0.5,
            textTransform: "none",
            "&:disabled": {
              color: "#B0BDC8",
              backgroundColor: "#DFE5EA",
              boxShadow: "0 0 0 1px inset #C7D1D9",
              border: "none"
            }
          },
          contained: ({ theme, ownerState }) => ({
            "&, &:hover, &:active": {
              boxShadow: "none"
            },
            "&:hover": {
              backgroundColor:
                ownerState.color && ownerState.color !== "inherit"
                  ? theme.palette[ownerState.color].light
                  : undefined
            },
            "&:active": {
              backgroundColor:
                ownerState.color && ownerState.color !== "inherit"
                  ? theme.palette[ownerState.color].dark
                  : undefined
            }
          }),
          outlined: ({ theme, ownerState }) => ({
            border: "none",
            boxShadow: "0 0 0 1px inset",
            "&:hover": {
              color:
                ownerState.color && ownerState.color !== "inherit"
                  ? theme.palette[ownerState.color].light
                  : undefined,
              boxShadow: "0 0 0 2px inset",
              border: "none",
              backgroundColor: "inherit"
            },
            "&:active": {
              color:
                ownerState.color && ownerState.color !== "inherit"
                  ? theme.palette[ownerState.color].contrastText
                  : undefined,
              backgroundColor:
                ownerState.color && ownerState.color !== "inherit"
                  ? theme.palette[ownerState.color].dark
                  : undefined,
              boxShadow: "none",
              border: "none"
            }
          })
        }
      }
    }
  },
  ruRU
);
