import { TransitionProps } from "@mui/material/transitions";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1User } from "api/generated";
import { convertStringToPhone } from "utils/convertStringToPhone";
import { filterStringNumbers } from "utils/filterStringNumbers";
import { setServerError } from "utils/setServerError";

import { useEditEmployeeMutation } from "../hooks";

import { EmployeeForm, EmployeeFormInputs, EmployeeFormProps } from "./EmployeeForm";

interface ChangeEmployeeFormProps {
  open: boolean;
  employee: LegalCabinetGatewayV1User;
  onClose: () => void;
  TransitionProps?: TransitionProps;
}

export const ChangeEmployeeForm: FC<ChangeEmployeeFormProps> = ({
  open,
  employee,
  onClose,
  TransitionProps
}) => {
  const { t } = useTranslation();

  const form = useForm<EmployeeFormInputs>({
    defaultValues: {
      email: employee.email ?? "",
      fio: employee.fio ?? "",
      phone:
        employee.phone?.length === 11 ? convertStringToPhone(employee.phone) : employee.phone ?? ""
    }
  });

  const { mutate, isPending } = useEditEmployeeMutation();

  const handleSubmit: EmployeeFormProps["onSubmit"] = ({ email, fio, phone }) =>
    mutate(
      {
        uuid: employee.uuid,
        role: employee.role,
        email,
        fio,
        phone: filterStringNumbers(phone)
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            setServerError(form.setError, t, { message: error.message });
          } else {
            onClose();
          }
        },
        onError: (error) => setServerError(form.setError, t, { message: error?.message })
      }
    );

  return (
    <EmployeeForm
      title={t("employees.change_employee")}
      open={open}
      form={form}
      disabled={isPending}
      onClose={isPending ? undefined : onClose}
      onSubmit={handleSubmit}
      TransitionProps={TransitionProps}
    />
  );
};
