import { Alert, CircularProgress, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { formatNumberToKZT } from "utils/formatNumberToKZT";

import { useBalanceQuery } from "../hooks";

export const BalanceInfo: FC = () => {
  const { t } = useTranslation();
  const { data, isPending, error } = useBalanceQuery();

  const errorToRender = error || data?.data.error;
  const amount = parseInt(data?.data.amount ?? "0") / 100;
  const formattedAmount = formatNumberToKZT(amount);

  return (
    <Paper sx={{ padding: "56px 41px" }}>
      {errorToRender && (
        <Alert sx={{ mb: "16px" }} severity='error'>
          {errorToRender.message || t("common.errors.unknown_error")}
        </Alert>
      )}

      <Typography variant='h1'>{t("common.nav.balance")}</Typography>

      {isPending && <CircularProgress sx={{ mt: "16px" }} />}

      {amount !== undefined && (
        <Typography variant='h2' component='p'>
          {formattedAmount}
        </Typography>
      )}
    </Paper>
  );
};
