import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const BALANCE_QUERY_KEY = ["balance"];

export const useBalanceQuery = () =>
  useQuery({
    queryKey: BALANCE_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayCompanyBalance({})
  });
