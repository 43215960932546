/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorValidateError {
  field?: string;
  message?: string;
}

export interface SystemSettingsConfigCurrency {
  /** буквенный код валюты (ISO 4217). Например: RUB, KZT */
  alphaCode?: string;
  /** циферный код валюты (ISO 4217). Например: 643, 398 */
  numberCode?: string;
  /** utf8 символ валюты. Например: ₽ */
  symbol?: string;
  /** например: {ru: "Руб.", en: "Rub."} */
  title?: Record<string, string>;
}

export interface SystemSettingsConfigVehicleReference {
  /** Тип ТС, Например: car */
  type?: string;
  /** регулярное выражения для проверки ГРЗ */
  vrpRegexp?: string;
  /** если нажата галочка: Иностранный - Прочий */
  foreignVrpRegexp?: string;
  /** Название */
  title?: Record<string, string>;
  /** Маска для инпута: X XXX XX XXX */
  vrpMask?: string;
  /** Маска для инпута: A 000 AA 000 */
  placeholder?: string;
}

export interface LegalCabinetGatewayV1AddUserRequest {
  phone?: string;
  email?: string;
  /**
   * роль пользователя в компании:
   * supervisor - руководитель (доступно всё)
   * representative - представитель (ограничения по некоторым разделам)
   */
  role?: string;
  fio?: string;
}

export interface LegalCabinetGatewayV1Company {
  uuid?: string;
  name?: string;
  innOrBik?: string;
  email?: string;
  contactPhone?: string;
  /** ФИО руководителя - допустим ввод букв, спец.символов, кол-во = 150 символов */
  superviserFio?: string;
  /** Должность руководителя - допустим ввод букв, цифр, спец.символов, кол-во = 150 символов */
  superviserPosition?: string;
  /** Примечание - дополнительная информация по организации, допустим ввод букв, цифр, спец.символов, кол-во = 300 символов */
  note?: string;
  legalAddress?: string;
  postalAddress?: string;
  /** @format int64 */
  licenseExpiry?: string;
  /** Номер договора */
  contractNumber?: string;
  /**
   * Дата договора
   * @format int64
   */
  contractDate?: string;
}

export interface LegalCabinetGatewayV1CompanyAccount {
  uuid?: string;
  /**
   * баланс кошелька в минимальных единицах валюты (копейки)
   * @format uint64
   */
  balance?: string;
}

export interface LegalCabinetGatewayV1CompanyBalanceResponse {
  /** id аккаунта */
  uuid?: string;
  /** id юр. лица */
  companyUuid?: string;
  /**
   * количество (в мин единицах)
   * @format uint64
   */
  amount?: string;
  error?: LegalCabinetGatewayV1Error;
}

/**
 * История событий компании.
 */
export interface LegalCabinetGatewayV1CompanyEvent {
  uuid?: string;
  title?: string;
  vehicleVrp?: string;
  /** @format int64 */
  date?: string;
  parkingNumber?: string;
  /** @format int64 */
  amount?: number;
  sign?: string;
  confirmingDocument?: string;
  /** @format int64 */
  startDate?: string;
  /** @format int64 */
  endDate?: string;
}

export interface LegalCabinetGatewayV1CompanyEventsFileRequest {
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
  /**
   * дата начала периода
   * @format int64
   */
  start?: string;
  /**
   * дата окончания периода
   * @format int64
   */
  end?: string;
  /** фильтр по типу события */
  kind?: LegalCabinetGatewayV1Kind;
  /** фильтр по номеру парковки */
  parkingNumber?: string;
  /** фильтр по номеру ТС */
  vehicleVrp?: string;
}

export interface LegalCabinetGatewayV1CompanyEventsFileResponse {
  body?: string;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1CompanyEventsRequest {
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
  /**
   * дата начала периода
   * @format int64
   */
  start?: string;
  /**
   * дата окончания периода
   * @format int64
   */
  end?: string;
  /** фильтр по типу события */
  kind?: LegalCabinetGatewayV1Kind;
  /** фильтр по номеру парковки */
  parkingNumber?: string;
  /** фильтр по номеру ТС */
  vehicleVrp?: string;
}

export interface LegalCabinetGatewayV1CompanyEventsResponse {
  /**
   * количество событий, всего
   * @format uint64
   */
  total?: string;
  companyEvents?: LegalCabinetGatewayV1CompanyEvent[];
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1ConfirmPinCodeRequest {
  /** Телефон для регистрации */
  phone?: string;
  /** Пин код полученный из смс. Длина всегда 4 руны. 0000 */
  pinCode?: string;
}

export interface LegalCabinetGatewayV1CreateDefaultResponse {
  uuid?: string;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1CreateVehicleRequest {
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  type?: LegalCabinetGatewayV1VehicleType;
  /**
   * - VRP_FORMAT_UNSPECIFIED: не установлено
   *  - VRP_FORMAT_LOCAL: внутренний
   *  - VRP_FORMAT_FOREIGN: иностранный
   */
  vrpFormat?: LegalCabinetGatewayV1VrpFormat;
  vrp?: string;
  label?: string;
  sts?: string;
  driverPhones?: string[];
}

export interface LegalCabinetGatewayV1DeleteVehicleResponse {
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1EditCompanyRequest {
  name?: string;
  innOrBik?: string;
  email?: string;
  contactPhone?: string;
  /** ФИО руководителя - допустим ввод букв, спец.символов, кол-во = 150 символов */
  superviserFio?: string;
  /** Должность руководителя - допустим ввод букв, цифр, спец.символов, кол-во = 150 символов */
  superviserPosition?: string;
  /** Примечание - дополнительная информация по организации, допустим ввод букв, цифр, спец.символов, кол-во = 300 символов */
  note?: string;
  legalAddress?: string;
  postalAddress?: string;
  /** @format date-time */
  licenseExpiry?: string;
  /** Номер договора */
  contractNumber?: string;
  /**
   * Дата договора
   * @format date-time
   */
  contractDate?: string;
}

export interface LegalCabinetGatewayV1EditCompanyResponse {
  uuid?: string;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1EditUserRequest {
  uuid?: string;
  phone?: string;
  email?: string;
  /**
   * роль пользователя в компании:
   * supervisor - руководитель (доступно всё)
   * representative - представитель (ограничения по некоторым разделам)
   */
  role?: string;
  fio?: string;
}

export interface LegalCabinetGatewayV1EditVehicleRequest {
  uuid?: string;
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  type?: LegalCabinetGatewayV1VehicleType;
  /**
   * - VRP_FORMAT_UNSPECIFIED: не установлено
   *  - VRP_FORMAT_LOCAL: внутренний
   *  - VRP_FORMAT_FOREIGN: иностранный
   */
  vrpFormat?: LegalCabinetGatewayV1VrpFormat;
  vrp?: string;
  label?: string;
  sts?: string;
  driverPhones?: string[];
}

export interface LegalCabinetGatewayV1EmptyOrErrorResponse {
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1Error {
  code?: string;
  message?: string;
  validateErrors?: ErrorValidateError[];
}

export interface LegalCabinetGatewayV1ExternalCompany {
  company?: LegalCabinetGatewayV1Company;
  supervisorPhone?: string;
}

export interface LegalCabinetGatewayV1GetCompaniesResponse {
  externalCompanies?: LegalCabinetGatewayV1ExternalCompany[];
  /** @format int32 */
  total?: number;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetCompanyResponse {
  company?: LegalCabinetGatewayV1Company;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetExternalCompanyResponse {
  externalCompany?: LegalCabinetGatewayV1ExternalCompany;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetParkingZonesRequest {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
}

export interface LegalCabinetGatewayV1GetParkingZonesResponse {
  zones?: LegalCabinetGatewayV1Zone[];
  /** @format int32 */
  total?: number;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetPinCodeRequest {
  /** Телефон для регистрации/аутентификации, без + */
  phone?: string;
}

export interface LegalCabinetGatewayV1GetTopicResponse {
  /** Тема обращения */
  topic?: LegalCabinetGatewayV1Topic;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetTopicsResponse {
  /** Список тем обращения */
  topics?: LegalCabinetGatewayV1Topic[];
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetUsersByCompanyUUIDRequest {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  q?: string;
}

export interface LegalCabinetGatewayV1GetUsersByCompanyUUIDResponse {
  users?: LegalCabinetGatewayV1User[];
  error?: LegalCabinetGatewayV1Error;
  /** @format int32 */
  total?: number;
}

export interface LegalCabinetGatewayV1GetVehicleResponse {
  vehicle?: LegalCabinetGatewayV1Vehicle;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1GetVehiclesByCompanyUUIDRequest {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  q?: string;
}

export interface LegalCabinetGatewayV1GetVehiclesByCompanyUUIDResponse {
  vehicles?: LegalCabinetGatewayV1Vehicle[];
  error?: LegalCabinetGatewayV1Error;
  /** @format int32 */
  total?: number;
}

export interface LegalCabinetGatewayV1GetVehiclesRequest {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
  vrpQuery?: string;
}

export interface LegalCabinetGatewayV1GetVehiclesResponse {
  vehicles?: LegalCabinetGatewayV1Vehicle[];
  /** @format int32 */
  total?: number;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1HealthCheckResponse {
  /** @format int64 */
  ttl?: string;
}

export interface LegalCabinetGatewayV1Jwt {
  accessToken?: string;
  refreshToken?: string;
  /**
   * время протухания access_token
   * @format int64
   */
  expiresIn?: string;
  error?: LegalCabinetGatewayV1Error;
}

export enum LegalCabinetGatewayV1Kind {
  KIND_EVENT_UNSPECIFIED = "KIND_EVENT_UNSPECIFIED",
  KIND_EVENT_ACCOUNTS_PAID_ROADSIDE = "KIND_EVENT_ACCOUNTS_PAID_ROADSIDE",
  KIND_EVENT_ACCOUNTS_HOLD_ROADSIDE = "KIND_EVENT_ACCOUNTS_HOLD_ROADSIDE",
  KIND_EVENT_PAYMENTS_ACCOUNT_REFILL = "KIND_EVENT_PAYMENTS_ACCOUNT_REFILL",
  KIND_EVENT_ACCOUNTS_CHARGED_ROADSIDE = "KIND_EVENT_ACCOUNTS_CHARGED_ROADSIDE"
}

export interface LegalCabinetGatewayV1Language {
  shortCode?: string;
  localizedName?: string;
}

export interface LegalCabinetGatewayV1ProfileResponse {
  /** информация о текущем пользователе */
  user?: LegalCabinetGatewayV1User;
  /** информация о компании */
  company?: LegalCabinetGatewayV1Company;
  /** баланс компании */
  account?: LegalCabinetGatewayV1CompanyAccount;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1RefreshTokenRequest {
  refreshToken?: string;
}

export interface LegalCabinetGatewayV1RegisterRequest {
  /** "Наименование организации" - обязательное */
  name?: string;
  /** "ИНН / БИН" - при регистрации необязательные */
  innOrBik?: string;
  /** Телефон для регистрации руководителя - обязательное */
  phone?: string;
  /** email для уведомлений и связи с организацией - необязательное */
  email?: string;
  /** Пин код полученный из смс. Длина всегда 4 руны. 0000 */
  pinCode?: string;
}

export interface LegalCabinetGatewayV1SendFeedbackRequest {
  /** Идентификатор темы обращения */
  topicUuid?: string;
  /** Почта отправителя */
  email?: string;
  /** Текст обращения */
  text?: string;
  /** uuid картинок */
  fileUuids?: string[];
}

export interface LegalCabinetGatewayV1SendFeedbackResponse {
  /** Идентификатор обращения */
  uuid?: string;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1SystemSettingsConfig {
  availableLanguages?: LegalCabinetGatewayV1Language[];
  currency?: SystemSettingsConfigCurrency;
  vehicles?: SystemSettingsConfigVehicleReference[];
}

export interface LegalCabinetGatewayV1Topic {
  /** Идентификатор темы */
  uuid?: string;
  /** Название темы */
  name?: LegalCabinetGatewayV1Translate;
  /** Почты для обратной связи */
  emails?: string[];
  /**
   * Порядок в сортировке
   * @format int32
   */
  order?: number;
  /** Является ли тема для страницы авторизации */
  isAuth?: boolean;
}

export interface LegalCabinetGatewayV1Translate {
  ru?: string;
  kz?: string;
  en?: string;
}

export interface LegalCabinetGatewayV1UpdateLicenseExpiryRequest {
  uuid?: string;
  /** @format date-time */
  licenseExpiry?: string;
  /** Номер договора */
  contractNumber?: string;
  /**
   * Дата договора
   * @format date-time
   */
  contractDate?: string;
}

export interface LegalCabinetGatewayV1UpdateLicenseExpiryResponse {
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1UploadFileRequest {
  /** Расширение файла (png, jpg, jpeg и т.д.) */
  extension?: string;
  /** Имя файла */
  name?: string;
  /** Содержимое файла в base64 */
  base64Content?: string;
  /** Тип содержимого файла (image/png и т.д.) */
  contentType?: string;
}

export interface LegalCabinetGatewayV1UploadFileResponse {
  uuid?: string;
  error?: LegalCabinetGatewayV1Error;
}

export interface LegalCabinetGatewayV1User {
  uuid?: string;
  phone?: string;
  email?: string;
  /**
   * роль пользователя в компании:
   * supervisor - руководитель (доступно всё)
   * representative - представитель (ограничения по некоторым разделам)
   */
  role?: string;
  fio?: string;
}

export interface LegalCabinetGatewayV1UuidRequest {
  uuid?: string;
}

export interface LegalCabinetGatewayV1Vehicle {
  uuid?: string;
  companyUuid?: string;
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  type?: LegalCabinetGatewayV1VehicleType;
  /**
   * - VRP_FORMAT_UNSPECIFIED: не установлено
   *  - VRP_FORMAT_LOCAL: внутренний
   *  - VRP_FORMAT_FOREIGN: иностранный
   */
  vrpFormat?: LegalCabinetGatewayV1VrpFormat;
  vrp?: string;
  label?: string;
  sts?: string;
  driverPhones?: string[];
}

/**
* - VEHICLE_TYPE_UNSPECIFIED: не установлено
 - VEHICLE_TYPE_CAR: легковой ТС
 - VEHICLE_TYPE_TRUCK: грузовой ТС
 - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
 - VEHICLE_TYPE_BUS: автобус
*/
export enum LegalCabinetGatewayV1VehicleType {
  VEHICLE_TYPE_UNSPECIFIED = "VEHICLE_TYPE_UNSPECIFIED",
  VEHICLE_TYPE_CAR = "VEHICLE_TYPE_CAR",
  VEHICLE_TYPE_TRUCK = "VEHICLE_TYPE_TRUCK",
  VEHICLE_TYPE_MOTORCYCLE = "VEHICLE_TYPE_MOTORCYCLE",
  VEHICLE_TYPE_BUS = "VEHICLE_TYPE_BUS"
}

/**
* - VRP_FORMAT_UNSPECIFIED: не установлено
 - VRP_FORMAT_LOCAL: внутренний
 - VRP_FORMAT_FOREIGN: иностранный
*/
export enum LegalCabinetGatewayV1VrpFormat {
  VRP_FORMAT_UNSPECIFIED = "VRP_FORMAT_UNSPECIFIED",
  VRP_FORMAT_LOCAL = "VRP_FORMAT_LOCAL",
  VRP_FORMAT_FOREIGN = "VRP_FORMAT_FOREIGN"
}

export interface LegalCabinetGatewayV1Zone {
  uuid?: string;
  number?: string;
}

export interface ProtobufAny {
  "@type"?: string;
  [key: string]: any;
}

export interface RpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import qs from "qs";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded"
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  public interceptors;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
    this.interceptors = this.instance.interceptors;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {})
      },
      params: query,
      responseType: responseFormat,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      data: body,
      url: path
    });
  };
}

/**
 * @title legal_cabinet_gateway_v1.proto
 * @version version not set
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  healthcheck = {
    /**
     * No description
     *
     * @name LegalCabinetGatewayHealthcheck
     * @request GET:/healthcheck
     */
    legalCabinetGatewayHealthcheck: (params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1HealthCheckResponse, RpcStatus>({
        path: `/healthcheck`,
        method: "GET",
        format: "json",
        ...params
      })
  };
  v1 = {
    /**
     * No description
     *
     * @name LegalCabinetGatewayConfirmPinCode
     * @summary Проверка пин-кода, получение токенов во время аутентификации
     * @request POST:/v1/auth/confirmpincode
     */
    legalCabinetGatewayConfirmPinCode: (
      body: LegalCabinetGatewayV1ConfirmPinCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1Jwt, RpcStatus>({
        path: `/v1/auth/confirmpincode`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetPinCode
     * @summary Регистрация/Аутентификация Получение нового pin кода через смс
     * @request POST:/v1/auth/getpincode
     */
    legalCabinetGatewayGetPinCode: (
      body: LegalCabinetGatewayV1GetPinCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1EmptyOrErrorResponse, RpcStatus>({
        path: `/v1/auth/getpincode`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayProfile
     * @summary Получение профиля текущего пользователя, проверка токена
     * @request GET:/v1/auth/profile
     */
    legalCabinetGatewayProfile: (params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1ProfileResponse, RpcStatus>({
        path: `/v1/auth/profile`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayRefreshToken
     * @summary Обновить авторизационный токен
     * @request POST:/v1/auth/refresh-token
     */
    legalCabinetGatewayRefreshToken: (
      body: LegalCabinetGatewayV1RefreshTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1Jwt, RpcStatus>({
        path: `/v1/auth/refresh-token`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayRegister
     * @summary Регистрация и получение токена
     * @request POST:/v1/auth/register
     */
    legalCabinetGatewayRegister: (
      body: LegalCabinetGatewayV1RegisterRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1Jwt, RpcStatus>({
        path: `/v1/auth/register`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetCompanyEvents
     * @summary История событий компании
     * @request POST:/v1/companies/events
     */
    legalCabinetGatewayGetCompanyEvents: (
      body: LegalCabinetGatewayV1CompanyEventsRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CompanyEventsResponse, RpcStatus>({
        path: `/v1/companies/events`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetCompanyEventsFile
     * @summary Получение файла событий
     * @request POST:/v1/companies/events/file
     */
    legalCabinetGatewayGetCompanyEventsFile: (
      body: LegalCabinetGatewayV1CompanyEventsFileRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CompanyEventsFileResponse, RpcStatus>({
        path: `/v1/companies/events/file`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetCompany
     * @summary Получение информации о компании
     * @request POST:/v1/companies/get
     */
    legalCabinetGatewayGetCompany: (body: object, params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1GetCompanyResponse, RpcStatus>({
        path: `/v1/companies/get`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayCompanyBalance
     * @summary Просмотр баланса юр. лица
     * @request POST:/v1/companies/getbalance
     */
    legalCabinetGatewayCompanyBalance: (body: object, params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1CompanyBalanceResponse, RpcStatus>({
        path: `/v1/companies/getbalance`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayEditCompany
     * @summary Редактирование информации о компании
     * @request POST:/v1/companies/update
     */
    legalCabinetGatewayEditCompany: (
      body: LegalCabinetGatewayV1EditCompanyRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1EditCompanyResponse, RpcStatus>({
        path: `/v1/companies/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayUpdateLicenseExpiry
     * @summary Обновление срока действия лицензии компании
     * @request POST:/v1/companies/update-license-expiry
     */
    legalCabinetGatewayUpdateLicenseExpiry: (
      body: LegalCabinetGatewayV1UpdateLicenseExpiryRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1UpdateLicenseExpiryResponse, RpcStatus>({
        path: `/v1/companies/update-license-expiry`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetSystemSettings
     * @summary Настройки системы
     * @request GET:/v1/config
     */
    legalCabinetGatewayGetSystemSettings: (params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1SystemSettingsConfig, RpcStatus>({
        path: `/v1/config`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewaySendFeedback
     * @summary Отправка обращения
     * @request POST:/v1/feedback/send
     */
    legalCabinetGatewaySendFeedback: (
      body: LegalCabinetGatewayV1SendFeedbackRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1SendFeedbackResponse, RpcStatus>({
        path: `/v1/feedback/send`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetTopic
     * @summary Получить тему обращения по uuid
     * @request POST:/v1/feedback/topic
     */
    legalCabinetGatewayGetTopic: (
      body: LegalCabinetGatewayV1UuidRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetTopicResponse, RpcStatus>({
        path: `/v1/feedback/topic`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetTopics
     * @summary Получить все темы обращения
     * @request GET:/v1/feedback/topics
     */
    legalCabinetGatewayGetTopics: (params: RequestParams = {}) =>
      this.request<LegalCabinetGatewayV1GetTopicsResponse, RpcStatus>({
        path: `/v1/feedback/topics`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayUploadFile
     * @summary Отправка файла
     * @request POST:/v1/files/upload
     */
    legalCabinetGatewayUploadFile: (
      body: LegalCabinetGatewayV1UploadFileRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1UploadFileResponse, RpcStatus>({
        path: `/v1/files/upload`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetParkingZones
     * @summary Получение списка парковочных зон
     * @request POST:/v1/parkingzones/list
     */
    legalCabinetGatewayGetParkingZones: (
      body: LegalCabinetGatewayV1GetParkingZonesRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetParkingZonesResponse, RpcStatus>({
        path: `/v1/parkingzones/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayAddUser
     * @summary добавление сотрудника
     * @request POST:/v1/users/add
     */
    legalCabinetGatewayAddUser: (
      body: LegalCabinetGatewayV1AddUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CreateDefaultResponse, RpcStatus>({
        path: `/v1/users/add`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayDeleteUser
     * @summary удаление сотрудника
     * @request POST:/v1/users/delete
     */
    legalCabinetGatewayDeleteUser: (
      body: LegalCabinetGatewayV1UuidRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1EmptyOrErrorResponse, RpcStatus>({
        path: `/v1/users/delete`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayEditUser
     * @summary редактирование сотрудника
     * @request POST:/v1/users/edit
     */
    legalCabinetGatewayEditUser: (
      body: LegalCabinetGatewayV1EditUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CreateDefaultResponse, RpcStatus>({
        path: `/v1/users/edit`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetUsersByCompanyUuid
     * @summary Получение списка сотрудников
     * @request POST:/v1/users/list
     */
    legalCabinetGatewayGetUsersByCompanyUuid: (
      body: LegalCabinetGatewayV1GetUsersByCompanyUUIDRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetUsersByCompanyUUIDResponse, RpcStatus>({
        path: `/v1/users/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayCreateVehicle
     * @summary Создание транспортного средства для юр. лиц
     * @request POST:/v1/vehicles/create
     */
    legalCabinetGatewayCreateVehicle: (
      body: LegalCabinetGatewayV1CreateVehicleRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CreateDefaultResponse, RpcStatus>({
        path: `/v1/vehicles/create`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayDeleteVehicle
     * @summary Удаление транспортного средства для юр. лиц
     * @request POST:/v1/vehicles/delete
     */
    legalCabinetGatewayDeleteVehicle: (
      body: LegalCabinetGatewayV1UuidRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1DeleteVehicleResponse, RpcStatus>({
        path: `/v1/vehicles/delete`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayGetVehicle
     * @summary Получение транспортного средства для юр. лиц
     * @request POST:/v1/vehicles/get
     */
    legalCabinetGatewayGetVehicle: (
      body: LegalCabinetGatewayV1UuidRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetVehicleResponse, RpcStatus>({
        path: `/v1/vehicles/get`,
        method: "POST",
        body: body,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayVehicles
     * @summary Получение транспортных средств для юр. лиц
     * @request POST:/v1/vehicles/list
     */
    legalCabinetGatewayVehicles: (
      body: LegalCabinetGatewayV1GetVehiclesRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetVehiclesResponse, RpcStatus>({
        path: `/v1/vehicles/list`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayVehiclesByCompanyUuid
     * @summary Получение списка траспортных средств пользователя для юр. лиц по сompany_uuid
     * @request POST:/v1/vehicles/listbycompanyuuid
     */
    legalCabinetGatewayVehiclesByCompanyUuid: (
      body: LegalCabinetGatewayV1GetVehiclesByCompanyUUIDRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1GetVehiclesByCompanyUUIDResponse, RpcStatus>({
        path: `/v1/vehicles/listbycompanyuuid`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name LegalCabinetGatewayEditVehicle
     * @summary Редактирование транспортного средства для юр. лиц
     * @request POST:/v1/vehicles/update
     */
    legalCabinetGatewayEditVehicle: (
      body: LegalCabinetGatewayV1EditVehicleRequest,
      params: RequestParams = {}
    ) =>
      this.request<LegalCabinetGatewayV1CreateDefaultResponse, RpcStatus>({
        path: `/v1/vehicles/update`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
}
