import { Container } from "@mui/material";
import { FC } from "react";

import { BalanceInfo } from "../components";

export const Balance: FC = () => {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <BalanceInfo />
    </Container>
  );
};
