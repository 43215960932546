import {
  IconButton,
  IconButtonProps,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme
} from "@mui/material";
import { forwardRef } from "react";

import { InvalidFieldIcon, SearchIcon } from "components/icons";
import { getFieldIcon } from "utils/getFieldIcon";

export type StyledTextFieldProps = {
  clearable?: boolean;
  valid?: boolean;
  search?: boolean;
  onClear?: IconButtonProps["onClick"];
  forcePlaceholder?: boolean;
} & TextFieldProps;

const VALID_CLASSNAME = "valid-field";

export const StyledTextField = forwardRef<HTMLDivElement, StyledTextFieldProps>(
  (
    { valid, clearable, search, onClear, error, InputProps, sx, forcePlaceholder, ...props },
    ref
  ) => {
    const theme = useTheme();

    const endAdornment = props.select ? null : (
      <InputAdornment position='end'>
        {clearable && (
          <IconButton onClick={onClear}>
            <InvalidFieldIcon />
          </IconButton>
        )}
        {getFieldIcon({ valid, error, defaultIcon: InputProps?.endAdornment })}
      </InputAdornment>
    );

    const startAdornment = search ? (
      <InputAdornment
        position='start'
        sx={{
          color: "#ACB6BE",
          ".MuiOutlinedInput-root.Mui-focused &": { color: "currentColor" }
        }}
      >
        <SearchIcon />
      </InputAdornment>
    ) : null;

    const forcePlaceholderSx = forcePlaceholder
      ? {
          "label[data-shrink=false]+.MuiInputBase-formControl .MuiOutlinedInput-input::-webkit-input-placeholder":
            {
              opacity: "1 !important"
            }
        }
      : undefined;

    return (
      <TextField
        {...props}
        className={valid ? VALID_CLASSNAME : undefined}
        error={error}
        InputProps={{ endAdornment, startAdornment, ...InputProps }}
        ref={ref}
        sx={{
          [`&.${VALID_CLASSNAME}`]: {
            ".MuiOutlinedInput-root": {
              backgroundColor: "#F9FFFE",
              ".MuiOutlinedInput-notchedOutline": {
                "&, &:hover": {
                  borderColor: theme.palette.primary.main
                }
              }
            }
          },
          ...forcePlaceholderSx,
          ...sx
        }}
      />
    );
  }
);
