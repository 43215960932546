import { useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledAutocomplete } from "components/form";

import { useVrpFilterQuery } from "../hooks";

interface VrpFilterProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
}

export const VrpFilter = <T extends FieldValues>({ control, name }: VrpFilterProps<T>) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const { isPending, data } = useVrpFilterQuery(open);

  const vehicles = data?.data.vehicles ?? [];

  const options = vehicles.filter(({ vrp }) => Boolean(vrp));

  return (
    <ControlledAutocomplete
      sx={{ gridArea: "vrp" }}
      disablePortal
      getOptionKey={(option) => option.uuid ?? ""}
      getOptionLabel={(option) => option.vrp ?? ""}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      options={options}
      loading={isPending}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      name={name}
      control={control}
      TextFieldProps={{ label: t("history.labels.vrp") }}
    />
  );
};
