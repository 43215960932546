import { isValid } from "date-fns";
import { Control, FieldValues, Path, SetFieldValue, UseFormGetValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledDatePicker } from "components/form";

interface VrpFilterProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  setValue: SetFieldValue<T>;
  periodStartName: Path<T>;
  getValues: UseFormGetValues<T>;
}

export const PeriodEndFilter = <T extends FieldValues>({
  control,
  name,
  setValue,
  periodStartName,
  getValues
}: VrpFilterProps<T>) => {
  const { t } = useTranslation();

  const handleAccept = () => {
    const periodStart = getValues(periodStartName) as Date | null;
    let periodEnd = getValues(name) as Date | null;
    const today = new Date();

    if (isValid(periodEnd) && (periodEnd as Date) > today) {
      setValue(name, today);
      periodEnd = today;
    }

    if (isValid(periodStart) && isValid(periodEnd) && (periodStart as Date) > (periodEnd as Date)) {
      setValue(periodStartName, periodEnd);
    }
  };

  return (
    <ControlledDatePicker
      control={control}
      name={name}
      onAccept={handleAccept}
      TextFieldProps={{
        sx: { gridArea: "periodEnd" },
        onBlur: handleAccept
      }}
      label={t("history.labels.period_end")}
      disableFuture
    />
  );
};
