import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FC, MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1User } from "api/generated";
import { EllipsisIcon } from "components/icons";
import { convertStringToPhone } from "utils/convertStringToPhone";

import { EmployeeRoles } from "../types";

interface EmployeesTableProps {
  sx?: SxProps;
  employees: LegalCabinetGatewayV1User[];
  onChangeEmployee?: (employee: LegalCabinetGatewayV1User) => void;
  onDeleteEmployee?: (employee: LegalCabinetGatewayV1User) => void;
  showOptions?: boolean;
}

export const EmployeesTable: FC<EmployeesTableProps> = ({
  employees,
  sx,
  onChangeEmployee,
  onDeleteEmployee,
  showOptions
}) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [activeEmployee, setActiveEmployee] = useState<LegalCabinetGatewayV1User | null>(null);

  const handleActionsOpen =
    (employee: LegalCabinetGatewayV1User): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      setMenuAnchor(e.currentTarget);
      setActiveEmployee(employee);
    };

  const handleChangeClick = () => {
    setMenuAnchor(null);
    activeEmployee && onChangeEmployee && onChangeEmployee(activeEmployee);
  };

  const handleDeleteClick = () => {
    setMenuAnchor(null);
    activeEmployee && onDeleteEmployee && onDeleteEmployee(activeEmployee);
  };

  const wrappedColumnsMaxWidth = showOptions ? "400px" : "436px";

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("employees.labels.fio")}</TableCell>
            <TableCell>{t("common.labels.phone")}</TableCell>
            <TableCell>{t("common.labels.email")}</TableCell>
            {showOptions && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody>
          {employees.map((employee) => (
            <TableRow key={employee.uuid}>
              <TableCell sx={{ maxWidth: wrappedColumnsMaxWidth }}>
                <Box sx={{ wordWrap: "break-word" }}>{employee.fio}</Box>
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {employee.phone?.length === 11
                  ? convertStringToPhone(employee.phone)
                  : employee.phone}
              </TableCell>

              <TableCell sx={{ maxWidth: wrappedColumnsMaxWidth }}>
                <Box sx={{ wordWrap: "break-word" }}>{employee.email}</Box>
              </TableCell>

              {showOptions && (
                <TableCell>
                  <IconButton onClick={handleActionsOpen(employee)}>
                    <EllipsisIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>

        {showOptions && (
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            TransitionProps={{ onExited: () => setActiveEmployee(null) }}
          >
            <MenuItem onClick={handleChangeClick}>{t("common.change")}</MenuItem>

            {activeEmployee?.role !== EmployeeRoles.SUPERVISOR && (
              <MenuItem onClick={handleDeleteClick}>{t("common.delete")}</MenuItem>
            )}
          </Menu>
        )}
      </Table>
    </TableContainer>
  );
};
