import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Translate } from "api/generated";

interface TranslationMap {
  code: string;
  key: keyof LegalCabinetGatewayV1Translate;
}

export const useGetApiTranslate = () => {
  const { i18n } = useTranslation();

  return (text: LegalCabinetGatewayV1Translate) => {
    const translationKeyMap: TranslationMap[] = [
      { code: "ru", key: "ru" },
      { code: "kk", key: "kz" },
      { code: "en", key: "en" }
    ];

    const currentTranslateKeyMap =
      translationKeyMap.find(({ code }) => i18n.language.startsWith(code)) ?? translationKeyMap[0];

    const currentTranslateKey = currentTranslateKeyMap.key;

    return text[currentTranslateKey] || text.ru || "";
  };
};
