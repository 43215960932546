import { LegalCabinetGatewayV1VrpFormat } from "api/generated";
import { filterStringNumbers } from "utils/filterStringNumbers";

import { VehicleFormInputs } from "../components/VehicleForm";

export const mapVehicleFormToServer = ({
  driverPhones,
  label,
  foreignOrSpecial,
  type,
  vrc,
  vrp
}: VehicleFormInputs) => {
  return {
    driverPhones: driverPhones.map(({ value }) => filterStringNumbers(value)),
    vrpFormat: foreignOrSpecial
      ? LegalCabinetGatewayV1VrpFormat.VRP_FORMAT_FOREIGN
      : LegalCabinetGatewayV1VrpFormat.VRP_FORMAT_LOCAL,
    sts: vrc.replace(/\s+/g, ""),
    label,
    type,
    vrp
  };
};
