import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requests } from "api";

import { COMPANY_VEHICLES_QUERY_KEY } from "./useCompanyVehiclesQuery";

export const useDeleteVehicleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requests.legalCabinetGatewayDeleteVehicle.bind(requests),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: COMPANY_VEHICLES_QUERY_KEY })
  });
};
