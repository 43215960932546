import { MenuItem } from "@mui/material";
import { Control, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Kind } from "api/generated";
import { ControlledTextField } from "components/form";

interface OperationFilterProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
}

export const OperationFilter = <T extends FieldValues>({
  name,
  control
}: OperationFilterProps<T>) => {
  const { t } = useTranslation();

  const operations = [
    {
      value: LegalCabinetGatewayV1Kind.KIND_EVENT_UNSPECIFIED,
      label: t("history.labels.all_operations")
    },
    {
      value: LegalCabinetGatewayV1Kind.KIND_EVENT_ACCOUNTS_HOLD_ROADSIDE,
      label: t("history.labels.parking_pay")
    },
    {
      value: LegalCabinetGatewayV1Kind.KIND_EVENT_ACCOUNTS_CHARGED_ROADSIDE,
      label: t("history.labels.parking_stop")
    },
    {
      value: LegalCabinetGatewayV1Kind.KIND_EVENT_ACCOUNTS_PAID_ROADSIDE,
      label: t("history.labels.refund")
    },
    {
      value: LegalCabinetGatewayV1Kind.KIND_EVENT_PAYMENTS_ACCOUNT_REFILL,
      label: t("history.labels.balance_replenishment")
    }
  ];

  return (
    <ControlledTextField
      name={name}
      control={control}
      sx={{ gridArea: "operation" }}
      select
      label={t("history.labels.operation")}
    >
      {operations.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </ControlledTextField>
  );
};
