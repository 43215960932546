import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1CompanyEvent } from "api/generated";
import { formatNumberToDecimal } from "utils/formatNumberToDecimal";
import { localizeDateString } from "utils/localizeDateString";

interface HistoryTableProps {
  sx?: SxProps;
  companyEvents: LegalCabinetGatewayV1CompanyEvent[];
}

const getPeriodCellContent = ({
  startDate,
  endDate
}: Pick<LegalCabinetGatewayV1CompanyEvent, "startDate" | "endDate">): string => {
  return [
    startDate !== undefined && parseInt(startDate),
    endDate !== undefined && parseInt(endDate)
  ]
    .filter(
      (dateNumber) => typeof dateNumber === "number" && dateNumber !== 0 && !isNaN(dateNumber)
    )
    .map((dateNumber) => localizeDateString(dateNumber as number))
    .join(" - ");
};

const getAmountCellContent = ({
  sign,
  amount
}: Pick<LegalCabinetGatewayV1CompanyEvent, "sign" | "amount">): string => {
  const formattedAmount = amount === undefined ? "" : formatNumberToDecimal(amount / 100);
  return amount === 0 ? formattedAmount : `${sign ?? ""}${formattedAmount}`;
};

export const HistoryTable: FC<HistoryTableProps> = ({ sx, companyEvents }) => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("history.table_columns.action")}</TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>{t("history.table_columns.vrp")}</TableCell>
            <TableCell>{t("history.table_columns.date")}</TableCell>
            <TableCell>{t("history.table_columns.parking")}</TableCell>
            <TableCell>{t("history.table_columns.parking_period")}</TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>{t("history.table_columns.amount")}</TableCell>
            <TableCell>{t("history.table_columns.confirmation_document")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyEvents.map(
            ({
              uuid,
              title,
              date,
              sign,
              amount,
              confirmingDocument,
              parkingNumber,
              vehicleVrp,
              endDate,
              startDate
            }) => (
              <TableRow key={uuid}>
                <TableCell>{title}</TableCell>
                <TableCell>{vehicleVrp}</TableCell>
                <TableCell>{date && localizeDateString(date)}</TableCell>
                <TableCell>{parkingNumber}</TableCell>
                <TableCell>{getPeriodCellContent({ startDate, endDate })}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {getAmountCellContent({ sign, amount })}
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: "310px", wordWrap: "break-word" }}>{confirmingDocument}</Box>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
