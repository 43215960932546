import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

const vrcMask = "aa 0000000000";
const vrcRegexp = /^\w{2} \d{10}$/;

interface VrcMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const VrcMaskInput = forwardRef<HTMLInputElement, VrcMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={vrcMask}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export const VrcField = <T extends FieldValues>({
  inputProps,
  InputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("vehicles.labels.vrc");

  return (
    <ControlledTextField
      rules={{
        pattern: {
          value: vrcRegexp,
          message: t("common.errors.invalid")
        }
      }}
      placeholder={ariaLabel}
      helperText={t("vehicles.helpers.vrc")}
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      InputProps={{ inputComponent: VrcMaskInput as any, ...InputProps }}
      isValid={({ field, fieldState }) => !fieldState.invalid && vrcRegexp.test(field.value)}
      defaultIsClearable
      {...props}
    />
  );
};
