import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

export const VehicleNameField = <T extends FieldValues>({
  rules,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("vehicles.labels.vehicle_name");

  return (
    <ControlledTextField
      rules={{
        maxLength: {
          value: 30,
          message: t("common.errors.max_length", { count: 30 })
        },
        ...rules
      }}
      placeholder={ariaLabel}
      inputProps={{ maxLength: 30, "aria-label": ariaLabel, ...inputProps }}
      defaultIsClearable
      defaultIsValid
      {...props}
    />
  );
};
