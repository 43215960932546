import { Box, Container } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

import { CompanyLogo } from "components/icons";
import { Paths } from "routes/paths";

import { NavItem } from "./NavItem";
import { navItems } from "./navItems";
import { ParentNavItem } from "./ParentNavItem";

export const NavBar: FC = () => {
  return (
    <Box component='nav' sx={{ boxShadow: "0px -4px 24px 0px #00000021" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          pt: 1.5,
          pb: 1.25
        }}
      >
        <Link to={Paths.Vehicles}>
          <CompanyLogo sx={{ color: "#637381" }} />
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "34px"
          }}
        >
          {navItems.map((navItem) =>
            "children" in navItem ? (
              <ParentNavItem key={navItem.path} {...navItem} />
            ) : (
              <NavItem key={navItem.path} {...navItem} />
            )
          )}
        </Box>
      </Container>
    </Box>
  );
};
