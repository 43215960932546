import { useMutation } from "@tanstack/react-query";

import { requests } from "api";
import { LegalCabinetGatewayV1CompanyEventsFileRequest } from "api/generated";
import { convertStringToCsvContent } from "utils/convertStringToCsvContent";
import {
  checkSupportsFileSystemAccess,
  downloadFileWithDialog,
  downloadFileWithLink
} from "utils/downloadFile";

const downloadHistoryFile = async (
  body: LegalCabinetGatewayV1CompanyEventsFileRequest
): Promise<void> => {
  const result = await requests.legalCabinetGatewayGetCompanyEventsFile(body);

  if (result.data.error) {
    throw result.data.error;
  }

  const useDialog = checkSupportsFileSystemAccess();
  const content = convertStringToCsvContent(result.data.body ?? "");
  const fileName = "history.csv";

  if (useDialog) {
    await downloadFileWithDialog({
      content,
      options: {
        suggestedName: fileName,
        types: [
          {
            accept: {
              "test/csv": [".csv"]
            }
          }
        ]
      }
    });
  } else {
    downloadFileWithLink({
      content,
      fileName
    });
  }
};

export const useDownloadHistoryMutation = () => {
  return useMutation({
    mutationFn: downloadHistoryFile
  });
};
