import {
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FC, MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Vehicle } from "api/generated";
import { EllipsisIcon } from "components/icons";

interface VehiclesTableProps {
  sx?: SxProps;
  vehicles: LegalCabinetGatewayV1Vehicle[];
  onChangeVehicle?: (vehicle: LegalCabinetGatewayV1Vehicle) => void;
  onDeleteVehicle?: (vehicle: LegalCabinetGatewayV1Vehicle) => void;
}

export const VehiclesTable: FC<VehiclesTableProps> = ({
  vehicles,
  sx,
  onChangeVehicle,
  onDeleteVehicle
}) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [activeVehicle, setActiveVehicle] = useState<LegalCabinetGatewayV1Vehicle | null>(null);

  const handleActionsOpen =
    (vehicle: LegalCabinetGatewayV1Vehicle): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      setMenuAnchor(e.currentTarget);
      setActiveVehicle(vehicle);
    };

  const handleActionsClose = () => {
    setMenuAnchor(null);
    setActiveVehicle(null);
  };

  const handleChangeClick = () => {
    setMenuAnchor(null);
    activeVehicle && onChangeVehicle && onChangeVehicle(activeVehicle);
    setActiveVehicle(null);
  };

  const handleDeleteClick = () => {
    setMenuAnchor(null);
    activeVehicle && onDeleteVehicle && onDeleteVehicle(activeVehicle);
    setActiveVehicle(null);
  };

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("vehicles.table_columns.vrp")}</TableCell>
            <TableCell>{t("vehicles.table_columns.vrc")}</TableCell>
            <TableCell>{t("vehicles.table_columns.name")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((vehicle) => (
            <TableRow key={vehicle.uuid}>
              <TableCell>{vehicle.vrp}</TableCell>
              <TableCell>{vehicle.sts}</TableCell>
              <TableCell>{vehicle.label}</TableCell>
              <TableCell>
                <IconButton onClick={handleActionsOpen(vehicle)}>
                  <EllipsisIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleActionsClose}>
          <MenuItem onClick={handleChangeClick}>{t("common.change")}</MenuItem>
          <MenuItem onClick={handleDeleteClick}>{t("common.delete")}</MenuItem>
        </Menu>
      </Table>
    </TableContainer>
  );
};
