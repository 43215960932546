import { Button, SxProps } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useRouteMatch } from "hooks";

export interface NavItemProps {
  position?: "header" | "footer";
  translateKey: string;
  path: string;
  disabled?: boolean;
}

export const NavItem: FC<NavItemProps> = ({
  position = "header",
  path,
  translateKey,
  disabled
}) => {
  const { t } = useTranslation();

  const routeMatch = useRouteMatch([path]);
  const activeItemPath = routeMatch?.pathname;

  const sx: SxProps =
    position === "header"
      ? {
          fontSize: "14px",
          color: activeItemPath ? "#151828" : "#637381",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "unset",
            color: "#151828"
          }
        }
      : {
          fontSize: "12px",
          color: "#FFFFFF",
          fontWeight: activeItemPath ? 500 : 400,
          textTransform: "none",
          "&:hover": {
            backgroundColor: "unset",
            color: "#E9EDF4"
          }
        };

  return (
    <Button component={Link} to={path} sx={sx} disableRipple disabled={disabled}>
      {t(translateKey)}
    </Button>
  );
};
