import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const PlusIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.99994 12.0992H18.1983'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M12.0992 18.1983V5.99994'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </SvgIcon>
  );
};
