import { Alert, Box, CircularProgress, Paper, Typography } from "@mui/material";
import { endOfDay } from "date-fns";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Error } from "api/generated";
import { Pagination } from "components/pagination";

import { useEventsQuery } from "../hooks";
import { HistoryRequestFilters } from "../types";

import { HistoryFilters, HistoryFiltersInputs } from "./HistoryFilters";
import { HistoryTable } from "./HistoryTable";
import { LoadHistory } from "./LoadHistory";

const convertPeriodToRequest = (period?: Date | null): string | undefined => {
  const periodNumber = period?.valueOf();

  return typeof periodNumber !== "number" || isNaN(periodNumber)
    ? undefined
    : periodNumber.toString();
};

export const HistoryManagement: FC = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [filters, setFilters] = useState<HistoryRequestFilters>({});

  const { data, isPending, error } = useEventsQuery({
    offset: `${page - 1}`,
    limit: rowsPerPage.toString(),
    ...filters
  });

  const getErrorToRender = (): Error | LegalCabinetGatewayV1Error | null => {
    const dataError = data?.data.error;

    if (dataError?.code === "not_found_operations_0") {
      return error;
    } else {
      return dataError || error;
    }
  };

  const companyEvents = data?.data.companyEvents;
  const totalCompanyEvents = data?.data.total ? parseInt(data?.data.total) : 0;
  const companyEventsAreEmpty = !companyEvents || companyEvents.length === 0;
  const errorToRender = getErrorToRender();

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleFiltersSubmit = (inputs: HistoryFiltersInputs) => {
    setFilters({
      kind: inputs.operation,
      parkingNumber: inputs.parking?.number,
      vehicleVrp: inputs.vrp?.vrp,
      end: convertPeriodToRequest(inputs.periodEnd && endOfDay(inputs.periodEnd)),
      start: convertPeriodToRequest(inputs.periodStart)
    });
    setPage(1);
  };

  return (
    <Paper sx={{ paddingY: "56px", paddingX: "41px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "14px"
        }}
      >
        <Typography variant='h1'>{t("common.nav.history")}</Typography>
        <LoadHistory filters={filters} />
      </Box>

      <HistoryFilters sx={{ mt: "16px" }} onSubmit={handleFiltersSubmit} />

      {isPending && <CircularProgress sx={{ mt: "16px" }} />}

      {errorToRender && (
        <Alert severity='error' sx={{ mt: "16px" }}>
          {errorToRender.message}
        </Alert>
      )}

      {!isPending && companyEventsAreEmpty && (
        <Typography variant='h2' component='p' sx={{ mt: "16px" }}>
          {t("history.empty_history")}
        </Typography>
      )}

      {!companyEventsAreEmpty && (
        <>
          <HistoryTable companyEvents={companyEvents} sx={{ mt: "16px" }} />

          <Pagination
            sx={{ mt: "16px" }}
            count={totalCompanyEvents}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </Paper>
  );
};
