import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText
} from "@mui/material";
import { ReactNode, useId } from "react";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";

import { CheckboxCheckedIcon, CheckboxIcon } from "components/icons";

export type ControlledCheckboxProps<T extends FieldValues> = FormControlProps &
  Omit<ControllerProps<T>, "render"> & {
    label: ReactNode;
    FormControlLabelProps?: Omit<FormControlLabelProps, "label" | "control">;
  };

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,
  label,
  FormControlLabelProps,
  ...props
}: ControlledCheckboxProps<T>) => {
  const helperId = useId();

  const render: ControllerProps<T>["render"] = ({ field: { value, ...field }, fieldState }) => {
    const errorMessage = fieldState.error?.message;
    const showError = Boolean(errorMessage);

    return (
      <FormControl {...props}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              inputProps={{ "aria-describedby": helperId }}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              {...field}
            />
          }
          label={label}
          {...FormControlLabelProps}
        />
        {showError && (
          <FormHelperText error id={helperId}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={render}
    />
  );
};
