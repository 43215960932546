import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const FEEDBACK_TOPICS_QUERY_KEY = ["feedback_topics"];

export const useFeedbackTopicsQuery = () =>
  useQuery({
    queryKey: FEEDBACK_TOPICS_QUERY_KEY,
    queryFn: () => requests.legalCabinetGatewayGetTopics()
  });
