import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";

export const NoteField = <T extends FieldValues>({
  rules,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const ariaLabel = t("company_details.labels.note");

  return (
    <ControlledTextField
      rules={{
        maxLength: {
          value: 300,
          message: t("common.errors.max_length", { count: 300 })
        },
        ...rules
      }}
      inputProps={{ maxLength: 300, "aria-label": ariaLabel, ...inputProps }}
      placeholder={ariaLabel}
      defaultIsValid
      {...props}
    />
  );
};
