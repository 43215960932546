import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { MobileAppLink } from "./MobileAppLink";

export const MobileApps: FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant='layout'>{t("common.footer.mobile_apps")}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          mt: "27px"
        }}
      >
        <MobileAppLink href={window.APP_STORE_URL || "#"} imgSrc='/img/app-store.png' />
        <MobileAppLink href={window.GOOGLE_PLAY_URL || "#"} imgSrc='/img/google-play.png' />
      </Box>
    </Box>
  );
};
