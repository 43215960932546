import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ControlledTextField, ControlledTextFieldProps } from "./ControlledTextField";

interface PinCodeMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PinCodeMaskInput = forwardRef<HTMLInputElement, PinCodeMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={"0000"}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export const PinCodeField = <T extends FieldValues>({
  rules,
  InputProps,
  inputProps,
  ...props
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation();

  const isValid: ControlledTextFieldProps<T>["isValid"] = ({ field, fieldState }) => {
    return field.value.length === 4 && !fieldState.invalid;
  };

  const ariaLabel = t("common.labels.pin_code");

  return (
    <ControlledTextField
      rules={{
        required: {
          value: true,
          message: t("common.errors.required_field")
        },
        minLength: {
          value: 4,
          message: t("auth.errors.auth_pincode_incorrect")
        },
        maxLength: {
          value: 4,
          message: t("auth.errors.auth_pincode_incorrect")
        },
        ...rules
      }}
      placeholder={ariaLabel}
      InputProps={{ inputComponent: PinCodeMaskInput as any, ...InputProps }}
      inputProps={{ "aria-label": ariaLabel, ...inputProps }}
      isValid={isValid}
      {...props}
    />
  );
};
