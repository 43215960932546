import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "./footer";
import { NavBar } from "./nav-bar";
import { Top } from "./top";

export const Layout: FC = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <Box component='header'>
        <Top />
        <NavBar />
      </Box>

      <Outlet />

      <Footer />
    </Box>
  );
};
