import { Box, Typography } from "@mui/material";
import type { FC } from "react";

interface SimpleRouteProps {
  title?: string;
}

export const SimpleRoute: FC<SimpleRouteProps> = ({ title }) => {
  return (
    <Box>
      <Typography>{title ?? "SimpleRoute"}</Typography>
    </Box>
  );
};
