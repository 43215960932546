export const fileToDataURL = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = reject;
  });

export const getBase64Content = (dataURL: string) => {
  return dataURL.replace("data:", "").replace(/^.+,/, "");
};

export const getFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");

  if (lastDotIndex === -1) {
    return "";
  }

  return filename.substring(lastDotIndex + 1);
};

export const getFilenameWithoutExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");

  if (lastDotIndex === -1) {
    return filename;
  }

  return filename.substring(0, lastDotIndex);
};
