import { MenuItem, SxProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ControlledTextField, ControlledTextFieldProps } from "components/form";
import { useAuthContext } from "features/auth";
import { useGetApiTranslate } from "hooks";

import { useFeedbackTopicsQuery } from "../hooks";
import { FeedbackFormInputs } from "../types";

type TopicSelectProps = {
  setValue: UseFormSetValue<FeedbackFormInputs>;
  watch: UseFormWatch<FeedbackFormInputs>;
  control: Control<FeedbackFormInputs>;
  sx?: SxProps;
  fullWidth?: boolean;
};

type StyledSelectProps = ControlledTextFieldProps<FeedbackFormInputs> & {
  watch: UseFormWatch<FeedbackFormInputs>;
};

const StyledSelect: FC<StyledSelectProps> = ({ sx, watch, ...props }) => {
  const currentValue = watch("topic");
  const [selectPaperMaxWidth, setSelectPaperMaxWidth] = useState<number>(0);

  return (
    <ControlledTextField
      select
      sx={{
        ...sx,
        ".MuiInputBase-root.MuiOutlinedInput-root": {
          minHeight: "48px",
          height: "auto",
          whiteSpace: "unset",
          wordWrap: "break-word"
        },
        ".MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select": {
          wordWrap: "break-word",
          whiteSpace: "wrap",
          color: currentValue ? undefined : "#ACB6BE"
        },
        ".MuiOutlinedInput-notchedOutline": {
          top: 0,
          legend: {
            display: "none"
          }
        }
      }}
      SelectProps={{
        displayEmpty: true,
        onOpen: (e) => setSelectPaperMaxWidth(e.currentTarget.clientWidth),
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                maxHeight: "365px",
                maxWidth: selectPaperMaxWidth,
                ".MuiButtonBase-root.MuiMenuItem-root": {
                  whiteSpace: "unset",
                  wordBreak: "break-all"
                }
              }
            }
          }
        }
      }}
      defaultIsValid
      {...props}
    />
  );
};

export const TopicSelect: FC<TopicSelectProps> = ({ setValue, control, fullWidth, sx, watch }) => {
  const { t } = useTranslation();
  const getApiTranslate = useGetApiTranslate();
  const { isSignedIn } = useAuthContext();
  const { data, isLoading } = useFeedbackTopicsQuery();

  const topics = data?.data.topics;
  const sortedTopics = topics?.sort((a, b) => (a.order ?? 1) - (b.order ?? 1));

  useEffect(() => {
    if (isSignedIn) {
      return;
    }

    const authTopic = topics?.find(({ isAuth }) => isAuth);
    setValue("topic", authTopic?.uuid ?? "");
  }, [isSignedIn, topics, setValue]);

  return (
    <StyledSelect
      sx={sx}
      watch={watch}
      disabled={!isSignedIn || isLoading}
      label={t("feedback.labels.topic")}
      name='topic'
      control={control}
      rules={{
        required: {
          value: true,
          message: t("common.errors.required_field")
        }
      }}
      fullWidth={fullWidth}
    >
      <MenuItem value='' sx={{ display: "none" }}>
        {t("feedback.placeholders.topic")}
      </MenuItem>
      {sortedTopics?.map(({ uuid, name }) => (
        <MenuItem key={uuid} value={uuid}>
          {name ? getApiTranslate(name) : ""}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
