import { Controller, ControllerProps, FieldValues } from "react-hook-form";

import { StyledTextField, StyledTextFieldProps } from "./StyledTextField";

type CheckField<T extends FieldValues> = (
  ...params: Parameters<ControllerProps<T>["render"]>
) => boolean;

export type ControlledTextFieldProps<T extends FieldValues> = Omit<ControllerProps<T>, "render"> &
  StyledTextFieldProps & {
    defaultIsValid?: boolean;
    isValid?: CheckField<T>;
    defaultIsClearable?: boolean;
    isClearable?: CheckField<T>;
    controllerDisabled?: boolean;
  };

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  helperText,
  error,
  defaultIsValid,
  isValid,
  defaultIsClearable,
  isClearable,
  controllerDisabled,
  ...TextFieldProps
}: ControlledTextFieldProps<T>) => {
  const render: ControllerProps<T>["render"] = ({ field, fieldState, formState }) => {
    const hasError = fieldState.invalid || error;

    const isValidInner: CheckField<T> | undefined = defaultIsValid
      ? ({ field: { value }, fieldState: { invalid } }) =>
          typeof value === "string" && value.length > 0 && !invalid
      : isValid;

    const isClearableInner: CheckField<T> | undefined = defaultIsClearable
      ? ({ field: { value } }) => typeof value === "string" && value.length > 0
      : isClearable;

    const valid = isValidInner && isValidInner({ field, fieldState, formState });
    const clearable = isClearableInner && isClearableInner({ field, fieldState, formState });

    const { ref: inputRef, ...restField } = field;

    return (
      <StyledTextField
        helperText={fieldState.error?.message || helperText}
        error={hasError}
        inputRef={inputRef}
        valid={valid}
        clearable={clearable}
        {...TextFieldProps}
        {...restField}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      disabled={controllerDisabled}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={render}
    />
  );
};
