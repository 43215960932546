import type { FC } from "react";

import { SignUpForm, FormWrapper } from "../components";

export const SignUp: FC = () => {
  return (
    <FormWrapper>
      <SignUpForm />
    </FormWrapper>
  );
};
