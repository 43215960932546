import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { InstagramIcon, TelegramIcon } from "components/icons";

import { ContactLink } from "../top";

export const SocialLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant='layout'>{t("common.footer.social_links")}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          mt: "24px"
        }}
      >
        <ContactLink href={window.INSTAGRAM_URL || "#"} light>
          <InstagramIcon />
        </ContactLink>

        <ContactLink href={window.TELEGRAM_URL || "#"} light>
          <TelegramIcon />
        </ContactLink>
      </Box>
    </Box>
  );
};
