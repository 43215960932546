import { Container } from "@mui/material";
import { FC } from "react";

import { DetailsManagement } from "../components";

export const Details: FC = () => {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <DetailsManagement />
    </Container>
  );
};
