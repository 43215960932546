import { IconButton, IconButtonProps } from "@mui/material";
import { FC } from "react";

import { CloseIcon } from "components/icons";

export const CloseDialogButton: FC<IconButtonProps> = ({ sx, disableRipple = true, ...props }) => {
  return (
    <IconButton
      {...props}
      disableRipple={disableRipple}
      sx={{
        position: "absolute",
        right: "14px",
        top: "14px",
        width: "24px",
        height: "24px",
        backgroundColor: "#E9EDF3",
        color: "#637381",
        "&:hover": {
          backgroundColor: "#FBFCFF",
          color: "#849BAE",
          border: "2px solid #CCCCCC"
        },
        "&:active": {
          border: "none",
          backgroundColor: "#637381",
          color: "#FFFFFF"
        },
        ...sx
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};
