import { forwardRef } from "react";
import { FieldValues, RegisterOptions, UseFormClearErrors, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

import { checkPhoneIsValid } from "services/checkPhoneIsValid";

import { ControlledTextField, ControlledTextFieldProps } from "./ControlledTextField";

const phoneMask = "+{7} (000) 000-00-00";
const phoneRegexp = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;

interface PhoneMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneMaskInput = forwardRef<HTMLInputElement, PhoneMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={phoneMask}
        inputRef={ref}
        // type any is from MUI docs
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        prepare={(value, masked) => {
          if (!masked.value.startsWith("+7") && value.startsWith("7") && value.length < 11) {
            return `7${value}`;
          } else {
            return value;
          }
        }}
      />
    );
  }
);

type PhoneFieldProps<T extends FieldValues> = ControlledTextFieldProps<T> & {
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
};

export const PhoneField = <T extends FieldValues>({
  name,
  rules,
  trigger,
  clearErrors,
  InputProps,
  inputProps,
  ...props
}: PhoneFieldProps<T>) => {
  const { t } = useTranslation();

  const handleChange: RegisterOptions["onChange"] = (e) => {
    const valueLength = e?.target?.value?.length;

    if (valueLength === 18 || valueLength === 0) {
      trigger(name);
    } else {
      clearErrors(name);
    }
  };

  const isValid: ControlledTextFieldProps<T>["isValid"] = ({ field, fieldState }) => {
    return checkPhoneIsValid({ phone: field.value, invalid: fieldState.invalid });
  };

  return (
    <ControlledTextField
      name={name}
      rules={{
        pattern: {
          value: phoneRegexp,
          message: t("auth.errors.invalid_phone")
        },
        required: {
          value: true,
          message: t("common.errors.required_field")
        },
        onChange: handleChange,
        ...rules
      }}
      placeholder='+7 (xxx) xxx-xx-xx'
      InputProps={{ inputComponent: PhoneMaskInput as any, ...InputProps }}
      inputProps={{ "aria-label": t("common.labels.phone"), ...inputProps }}
      isValid={isValid}
      {...props}
    />
  );
};
