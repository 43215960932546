import { Link } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "features/auth";

export const SignOutButton: FC = () => {
  const { t } = useTranslation();
  const { signOut } = useAuthContext();

  return (
    <Link onClick={signOut} variant='layout' component='button' underline='none'>
      {t("auth.sign_out")}
    </Link>
  );
};
