import { checkPhoneIsValid } from "./checkPhoneIsValid";

interface CheckCanConfirmPhoneOptions {
  repeatSeconds: number;
  phone: string;
  invalid?: boolean;
}

export const checkConfirmPhoneDisabled = ({
  repeatSeconds,
  phone,
  invalid
}: CheckCanConfirmPhoneOptions) => {
  return repeatSeconds > 0 || !checkPhoneIsValid({ phone, invalid });
};
