import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const DropDownIcon: FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox='0 0 8 4' {...props} sx={{ width: "auto", height: "3px", ...sx }}>
      <svg viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.66356 0.5L3.64538 3.5L0.627197 0.5'
          stroke='currentColor'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
