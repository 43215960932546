import { useMutation } from "@tanstack/react-query";

import { requests } from "api";
import { LegalCabinetGatewayV1SendFeedbackRequest } from "api/generated";

export const useFeedbackSendMutation = () => {
  return useMutation({
    mutationFn: (body: LegalCabinetGatewayV1SendFeedbackRequest) =>
      requests.legalCabinetGatewaySendFeedback(body)
  });
};
