import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const DeleteIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 30 30' {...props}>
      <svg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.5625 12.6316V20.2105H11.6875V12.6316H13.5625Z' fill='currentColor' />
        <path d='M17.3125 20.2105V12.6316H15.4375V20.2105H17.3125Z' fill='currentColor' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.75 8.84211C10.75 7.27245 12.0092 6 13.5625 6H15.4375C16.9908 6 18.25 7.27245 18.25 8.84211H22V10.7368H21.0625V21.1579C21.0625 22.7275 19.8033 24 18.25 24H10.75C9.1967 24 7.9375 22.7275 7.9375 21.1579V10.7368H7V8.84211H10.75ZM13.5625 7.89474H15.4375C15.9553 7.89474 16.375 8.31889 16.375 8.84211H12.625C12.625 8.31889 13.0447 7.89474 13.5625 7.89474ZM9.8125 10.7368V21.1579C9.8125 21.6811 10.2322 22.1053 10.75 22.1053H18.25C18.7678 22.1053 19.1875 21.6811 19.1875 21.1579V10.7368H9.8125Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
