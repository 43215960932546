import { Box, SxProps, Typography } from "@mui/material";
import { FC } from "react";

import { PageButtons } from "./PageButtons";
import { PageTextField } from "./PageTextField";
import { RowsPerPageSelect } from "./RowsPerPageSelect";

interface PaginationProps {
  sx?: SxProps;
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  sx,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange
}) => {
  const lastPage = Math.ceil(count / rowsPerPage);
  const index = page - 1;
  const from = index * rowsPerPage + 1;
  const to = from + rowsPerPage - 1;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "16px",
        ...sx
      }}
    >
      <RowsPerPageSelect
        value={rowsPerPage}
        onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
      />

      <Typography>
        {from}-{to <= count ? to : count} из {count}
      </Typography>

      <PageTextField key={page} page={page} lastPage={lastPage} onPageChange={onPageChange} />

      <PageButtons page={page} lastPage={lastPage} onPageChange={onPageChange} />
    </Box>
  );
};
