import { Alert, Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { LegalCabinetGatewayV1User } from "api/generated";
import { Pagination } from "components/pagination";
import { useProfileQuery } from "features/auth";

import { useEmployeesQuery } from "../hooks";
import { EmployeeRoles } from "../types";

import { AddEmployeeForm } from "./AddEmployeeForm";
import { ChangeEmployeeForm } from "./ChangeEmployeeForm";
import { DeleteEmployeeDialog } from "./DeleteEmployeeDialog";
import { EmployeesSearchField } from "./EmployeesSearchField";
import { EmployeesTable } from "./EmployeesTable";

export const EmployeesManagement: FC = () => {
  const { data: profileData } = useProfileQuery();
  const isSupervisor = profileData?.data.user?.role === EmployeeRoles.SUPERVISOR;

  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [searchString, setSearchString] = useState<string>("");

  const { data, isPending, error } = useEmployeesQuery(
    page - 1,
    rowsPerPage,
    searchString.length >= 3 ? searchString : ""
  );

  const employees = data?.data.users;
  const totalEmployees = data?.data.total ?? 0;
  const employeesAreEmpty = !employees || employees.length === 0;
  const errorToRender = error || data?.data.error;

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const [changeDialogOpen, setChangeDialogOpen] = useState<boolean>(false);
  const [employeeToChange, setEmployeeToChange] = useState<LegalCabinetGatewayV1User | null>(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [employeeToDelete, setEmployeeToDelete] = useState<LegalCabinetGatewayV1User | null>(null);

  const handleSearchStringChange = useDebouncedCallback((newSearchString: string) => {
    setSearchString(newSearchString);
    setPage(1);
  }, 1000);

  const handleChangeEmployee = (employee: LegalCabinetGatewayV1User) => {
    setChangeDialogOpen(true);
    setEmployeeToChange(employee);
  };

  const handleDeleteEmployee = (employee: LegalCabinetGatewayV1User) => {
    setDeleteDialogOpen(true);
    setEmployeeToDelete(employee);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  return (
    <Paper sx={{ paddingY: "56px", paddingX: "41px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "14px"
        }}
      >
        <Typography variant='h1'>{t("common.nav.employees")}</Typography>

        {isSupervisor && (
          <Button variant='contained' onClick={() => setAddDialogOpen(true)}>
            {t("common.add")}
          </Button>
        )}
      </Box>

      {(!employeesAreEmpty || searchString) && (
        <EmployeesSearchField
          sx={{ mt: "16px" }}
          fullWidth
          onChange={(e) => handleSearchStringChange(e.target.value)}
          onClear={() => handleSearchStringChange("")}
        />
      )}

      {isPending && <CircularProgress sx={{ mt: "16px" }} />}

      {errorToRender && (
        <Alert severity='error' sx={{ mt: "16px" }}>
          {errorToRender.message}
        </Alert>
      )}

      {!isPending && employeesAreEmpty && (
        <Typography variant='h2' component='p' sx={{ mt: "16px" }}>
          {t("employees.empty_employees")}
        </Typography>
      )}

      {employees && employees.length > 0 && (
        <>
          <EmployeesTable
            showOptions={isSupervisor}
            employees={employees}
            sx={{ mt: "16px" }}
            onChangeEmployee={handleChangeEmployee}
            onDeleteEmployee={handleDeleteEmployee}
          />

          <Pagination
            sx={{ mt: "16px" }}
            count={totalEmployees}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      {isSupervisor && (
        <>
          <AddEmployeeForm open={addDialogOpen} onClose={() => setAddDialogOpen(false)} />

          {employeeToChange && (
            <ChangeEmployeeForm
              open={changeDialogOpen}
              onClose={() => setChangeDialogOpen(false)}
              employee={employeeToChange}
              TransitionProps={{ onExited: () => setEmployeeToChange(null) }}
            />
          )}

          {employeeToDelete && (
            <DeleteEmployeeDialog
              open={deleteDialogOpen}
              employee={employeeToDelete}
              onClose={() => setDeleteDialogOpen(false)}
              TransitionProps={{ onExited: () => setEmployeeToDelete(null) }}
            />
          )}
        </>
      )}
    </Paper>
  );
};
