import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requests } from "api";

import { EMPLOYEES_QUERY_KEY } from "./useEmployeesQuery";

export const useDeleteEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requests.legalCabinetGatewayDeleteUser.bind(requests),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: EMPLOYEES_QUERY_KEY })
  });
};
