import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type ConfirmPhoneButtonProps = {
  repeatSeconds: number;
  isRepeat?: boolean;
} & ButtonProps;

export const ConfirmPhoneButton: FC<ConfirmPhoneButtonProps> = ({
  variant = "outlined",
  repeatSeconds,
  isRepeat,
  ...props
}) => {
  const { t } = useTranslation();

  const getTitle = () => {
    if (repeatSeconds > 0) {
      return t("auth.repeat_confirm_phone_seconds", { repeatSeconds });
    } else if (isRepeat) {
      return t("auth.repeat_confirm_phone");
    } else {
      return t("auth.confirm_phone");
    }
  };

  return (
    <Button variant={variant} {...props}>
      {getTitle()}
    </Button>
  );
};
