import { FC } from "react";
import { Control, Controller, UseFieldArrayRemove } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeedbackFormInputs } from "../types";

import { FilePreview } from "./FilePreview";

interface FileFieldProps {
  control: Control<FeedbackFormInputs>;
  index: number;
  remove: UseFieldArrayRemove;
}

export const FileField: FC<FileFieldProps> = ({ control, index, remove }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={`files.${index}`}
      rules={{
        validate: ({ isUploading, uuid }) =>
          (!isUploading && Boolean(uuid)) || t("common.errors.file_uploading")
      }}
      render={({ field: { value }, fieldState: { error } }) => (
        <FilePreview
          file={value.file}
          isPending={value.isUploading}
          onDelete={() => remove(index)}
          error={error?.message}
        />
      )}
    />
  );
};
