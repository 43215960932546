import { Container } from "@mui/material";
import { FC } from "react";

import { NavItem, navItems } from "../nav-bar";

export const FooterNav: FC = () => {
  return (
    <Container
      component='nav'
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "34px",
        borderBottom: "1px solid #FFFFFF66",
        pb: "22px",
        mb: "43px"
      }}
    >
      {navItems.map((navItem) =>
        "children" in navItem ? (
          navItem.children.map((child) => <NavItem position='footer' key={child.path} {...child} />)
        ) : (
          <NavItem position='footer' key={navItem.path} {...navItem} />
        )
      )}
    </Container>
  );
};
