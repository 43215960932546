import { Box } from "@mui/material";
import { FC } from "react";

import { InstagramIcon, PhoneIcon, TelegramIcon } from "components/icons";
import { convertStringToPhone } from "utils/convertStringToPhone";

import { ContactLink } from "./ContactLink";

export const Contacts: FC = () => {
  const phoneConfig = window.CALL_CENTER_PHONE;
  const callCenterUrl = phoneConfig ? `tel:+${phoneConfig}` : "#";
  const phone = phoneConfig?.length === 11 ? convertStringToPhone(phoneConfig) : phoneConfig;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px"
      }}
    >
      <ContactLink href={window.INSTAGRAM_URL || "#"}>
        <InstagramIcon />
      </ContactLink>

      <ContactLink href={window.TELEGRAM_URL || "#"}>
        <TelegramIcon />
      </ContactLink>

      <ContactLink href={callCenterUrl}>
        <PhoneIcon />
      </ContactLink>

      <ContactLink underline='none' href={callCenterUrl} variant='layout'>
        CALL CENTER:{" "}
        <Box component='span' sx={{ fontWeight: 700 }}>
          {phone}
        </Box>
      </ContactLink>
    </Box>
  );
};
