import { AxiosRequestConfig } from "axios";

import { Api } from "./generated";
export { queryClient } from "./queryClient";

export const baseURL = window.BACKEND_URL || "https://legalgw.test.almaty-parking.kz";

const config: AxiosRequestConfig = { baseURL };

const apiInstance = new Api(config);

export const requests = apiInstance.v1;
export const interceptors = apiInstance.interceptors;
export const axiosInstance = apiInstance.instance;
