interface DownloadWithLinkFileOptions {
  content: Blob;
  fileName: string;
}

interface DownloadWithDialogFileOptions {
  content: Blob;
  options?: SaveFilePickerOptions;
}

export const downloadFileWithLink = ({ content, fileName }: DownloadWithLinkFileOptions) => {
  const objectURL = window.URL.createObjectURL(content);
  const link = document.createElement("a");
  link.setAttribute("href", objectURL);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const downloadFileWithDialog = async ({
  content,
  options
}: DownloadWithDialogFileOptions) => {
  try {
    // Show the file save dialog.
    const handle = await showSaveFilePicker(options);

    // Write to the file.
    const writable = await handle.createWritable();
    await writable.write(content);
    await writable.close();
    return;
  } catch (e) {
    if (e instanceof Error) {
      // Fail silently if the user has simply canceled the dialog.
      if (e.name !== "AbortError") {
        console.error(e.name, e.message);
        return;
      }
    }
  }
};

// Feature detection. The API needs to be supported
// and the app not run in an iframe.
export const checkSupportsFileSystemAccess = (): boolean => {
  if (!("showSaveFilePicker" in window)) {
    return false;
  }

  try {
    return window.self === window.top;
  } catch {
    return false;
  }
};
