import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const InvalidFieldIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_70_490)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 10.8169L8.43346 7.2536C8.10731 6.92775 7.57075 6.92775 7.24461 7.2536C6.91846 7.57944 6.91846 8.1155 7.24461 8.44135L10.8112 12.0046L7.24461 15.5679C6.91846 15.8937 6.91846 16.4298 7.24461 16.7556C7.57075 17.0815 8.10731 17.0815 8.43346 16.7556L12 13.1924L15.5665 16.7556C15.8927 17.0815 16.4292 17.0815 16.7554 16.7556C17.0815 16.4298 17.0815 15.8937 16.7554 15.5679L13.1888 12.0046L16.7554 8.44135C17.0815 8.1155 17.0815 7.57944 16.7554 7.2536C16.4292 6.91724 15.9032 6.91724 15.5665 7.24308L12 10.8169Z'
            fill='currentColor'
          />
        </g>
        <defs>
          <clipPath id='clip0_70_490'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
