import { useQuery } from "@tanstack/react-query";

import { requests } from "api";

export const EMPLOYEES_QUERY_KEY = ["employees"];

export const getEmployeesQueryKey = (page: number, rowsPerPage: number, searchString: string) => {
  const queryKey: (number | string)[] = [...EMPLOYEES_QUERY_KEY, page, rowsPerPage];

  if (searchString) {
    queryKey.push(searchString);
  }

  return queryKey;
};

export const useEmployeesQuery = (page: number, rowsPerPage: number, searchString: string) =>
  useQuery({
    queryKey: getEmployeesQueryKey(page, rowsPerPage, searchString),
    queryFn: () =>
      requests.legalCabinetGatewayGetUsersByCompanyUuid({
        page: page,
        limit: rowsPerPage,
        q: searchString
      })
  });
