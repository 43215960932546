import { Alert } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { LegalCabinetGatewayV1Error, LegalCabinetGatewayV1Vehicle } from "api/generated";
import { DeleteDialog } from "components/dialogs";

import { useDeleteVehicleMutation } from "../hooks";

interface DeleteVehicleDialogProps {
  open: boolean;
  vehicle: LegalCabinetGatewayV1Vehicle;
  onClose?: () => void;
  TransitionProps?: TransitionProps;
}

export const DeleteVehicleDialog: FC<DeleteVehicleDialogProps> = ({ open, vehicle, onClose }) => {
  const { t } = useTranslation();

  const { mutate, isPending, error, reset } = useDeleteVehicleMutation();
  const [successError, setSuccessError] = useState<LegalCabinetGatewayV1Error | null>(null);

  const handleConfirm = () =>
    mutate(
      { uuid: vehicle.uuid },
      {
        onSuccess: ({ data: { error } }) => {
          if (error) {
            setSuccessError(error);
          } else {
            onClose && onClose();
          }
        }
      }
    );

  const getErrorMessage = (): string => {
    if (!successError && !error) {
      return "";
    }

    let message: string | undefined;

    if (successError) {
      message =
        successError.code === "vehicle_error_6"
          ? t("vehicles.errors.edit_active_session")
          : successError.message;
    } else {
      message = error?.message;
    }

    return message || t("common.errors.unknown_error");
  };

  const errorToRender = getErrorMessage();
  const onCloseInner = isPending ? undefined : onClose;

  const resetDialog = () => {
    reset();
    setSuccessError(null);
  };

  return (
    <DeleteDialog
      open={open}
      title={t("vehicles.delete_vehicle")}
      onClose={onCloseInner}
      onCancel={onCloseInner}
      onConfirm={isPending ? undefined : handleConfirm}
      TransitionProps={{ onExited: () => resetDialog() }}
    >
      {errorToRender && (
        <Alert severity='error' sx={{ mt: "24px" }}>
          {errorToRender}
        </Alert>
      )}
    </DeleteDialog>
  );
};
