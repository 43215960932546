import { Box, Button, SxProps } from "@mui/material";
import { FC, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LegalCabinetGatewayV1Kind,
  LegalCabinetGatewayV1Vehicle,
  LegalCabinetGatewayV1Zone
} from "api/generated";

import { OperationFilter } from "./OperationFilter";
import { ParkingFilter } from "./ParkingFilter";
import { PeriodEndFilter } from "./PeriodEndFilter";
import { PeriodStartFilter } from "./PeriodStartFilter";
import { VrpFilter } from "./VrpFilter";

export interface HistoryFiltersInputs {
  vrp: LegalCabinetGatewayV1Vehicle | null;
  parking: LegalCabinetGatewayV1Zone | null;
  periodStart: Date | null;
  periodEnd: Date | null;
  operation: LegalCabinetGatewayV1Kind;
}

interface HistoryFiltersProps {
  sx?: SxProps;
  onSubmit?: SubmitHandler<HistoryFiltersInputs>;
}

export const HistoryFilters: FC<HistoryFiltersProps> = ({ sx, onSubmit }) => {
  const { control, reset, handleSubmit, setValue, getValues } = useForm<HistoryFiltersInputs>({
    defaultValues: {
      vrp: null,
      parking: null,
      periodStart: null,
      periodEnd: null,
      operation: LegalCabinetGatewayV1Kind.KIND_EVENT_UNSPECIFIED
    }
  });

  const { t } = useTranslation();

  const formRef = useRef<null | HTMLFormElement>(null);

  const handleResetClick = () => {
    reset();
    formRef.current?.requestSubmit();
  };

  return (
    <Box
      ref={formRef}
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(6, 1fr)"
        },
        gridTemplateAreas: {
          xs: `
          "vrp"
          "parking"
          "periodStart"
          "periodEnd"
          "operation"
          "submit"
          "reset"
          `,
          sm: `
          "vrp parking"
          "periodStart periodEnd"
          "operation operation"
          "submit reset"
          `,
          md: `
          "vrp vrp vrp parking parking parking"
          "periodStart periodStart periodEnd periodEnd operation operation"
          "submit reset . . . ."
          `
        },
        gap: "16px",
        ...sx
      }}
      component='form'
      onSubmit={onSubmit && handleSubmit(onSubmit)}
    >
      <VrpFilter control={control} name='vrp' />
      <ParkingFilter control={control} name='parking' />
      <PeriodStartFilter
        control={control}
        name='periodStart'
        periodEndName='periodEnd'
        setValue={setValue}
        getValues={getValues}
      />
      <PeriodEndFilter
        control={control}
        name='periodEnd'
        periodStartName='periodStart'
        setValue={setValue}
        getValues={getValues}
      />
      <OperationFilter control={control} name='operation' />
      <Button variant='contained' sx={{ gridArea: "submit" }} type='submit'>
        {t("common.apply")}
      </Button>
      <Button variant='outlined' sx={{ gridArea: "reset" }} onClick={handleResetClick}>
        {t("common.reset")}
      </Button>
    </Box>
  );
};
