import { Box, MenuItem, Select, SelectChangeEvent, SelectProps, styled } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { SelectSharpIcon } from "components/icons";

const StyledSelect: FC<SelectProps<string>> = (props) => (
  <Select
    {...props}
    MenuProps={{
      transitionDuration: 0,
      transformOrigin: {
        vertical: 1,
        horizontal: "center"
      },
      slotProps: {
        paper: {
          sx: {
            boxShadow: "none",
            borderRadius: "0 0 12px 12px",
            border: "none",
            backgroundColor: "#748594",
            ".MuiList-root": {
              padding: 0
            }
          }
        }
      }
    }}
    IconComponent={SelectSharpIcon}
    sx={{
      width: "83px",
      height: "24px",
      backgroundColor: "#748594",
      color: "#fff",
      borderRadius: "12px",
      // eslint-disable-next-line quotes
      '&:has(.MuiSelect-select[aria-expanded="true"])': {
        borderRadius: "12px 12px 0 0"
      },
      ".MuiSelect-select": {
        padding: "4.5px 0 4.5px 6px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        fontSize: "14px",
        fontWeight: 500
      },
      ".MuiOutlinedInput-notchedOutline": {
        "&, .Mui-focused &": {
          border: "none"
        }
      },
      ".MuiSelect-icon": {
        top: 0,
        right: "5px",
        color: "#fff"
      }
    }}
  />
);

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "4.5px 0 4.5px 6px",
  fontSize: "14px",
  fontWeight: 500,
  color: "#FFFFFF80",
  height: "24px",
  "&:hover": {
    color: "#FFFFFF",
    background: "transparent"
  }
});

export const LanguageSwitcher = () => {
  const languages = [
    { code: "ru", title: "RU" },
    { code: "kk", title: "KZ" }
  ];

  const { i18n } = useTranslation();

  const currentLanguage =
    languages.find(({ code }) => i18n.language.startsWith(code)) || languages[0];

  const handleChange = (e: SelectChangeEvent<string>) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <StyledSelect value={currentLanguage.code} onChange={handleChange}>
      {languages.map(({ code, title }) => (
        <StyledMenuItem
          value={code}
          key={code}
          sx={{
            display: currentLanguage.code === code ? "none" : "flex"
          }}
        >
          <Box
            component='img'
            src={`/img/languages/${code}.png`}
            sx={{ width: "15px", height: "auto" }}
          />
          {title}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};
