import { MenuItem } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { StyledTextField } from "components/form";
import { StyledTextFieldProps } from "components/form/StyledTextField";

const defaultOptions: number[] = [5, 10, 20];

type RowsPerPageSelectProps = StyledTextFieldProps & {
  options?: number[];
};

export const RowsPerPageSelect: FC<RowsPerPageSelectProps> = ({
  options = defaultOptions,
  sx,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledTextField
      select
      label={t("common.pagination.rows_per_page")}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "end",
        gap: "16px",
        flexWrap: "wrap",
        "label + .MuiOutlinedInput-root": {
          mt: 0
        },
        ".MuiInputLabel-root": {
          fontSize: "15px",
          color: "#637381"
        },
        ...sx
      }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};
