import { IconButtonProps } from "@mui/material";
import { FC } from "react";

import { DeleteIcon } from "components/icons";

import { FilledIconButton } from "./FilledIconButton";

export const DeleteIconButton: FC<IconButtonProps> = ({ sx, ...props }) => {
  return (
    <FilledIconButton
      sx={{
        "&:active": {
          backgroundColor: "#FF5651"
        },
        ...sx
      }}
      disableRipple
      {...props}
    >
      <DeleteIcon sx={{ width: 30, height: 30 }} />
    </FilledIconButton>
  );
};
