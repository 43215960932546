import { FC } from "react";
import { useTranslation } from "react-i18next";

import { StyledTextField, StyledTextFieldProps } from "components/form";

export const VehiclesSearchField: FC<StyledTextFieldProps> = (props) => {
  const { t } = useTranslation();

  const ariaLabel = t("vehicles.search");

  return (
    <StyledTextField
      search
      placeholder={ariaLabel}
      inputProps={{ "aria-label": ariaLabel }}
      {...props}
    />
  );
};
